import { Authentication, Device, Fleet } from '@formant/data-sdk';

export const authenticateAndGetDevice = async (): Promise<Device> => {
  await Authentication.waitTilAuthenticated();
  const device = await Fleet.getCurrentDevice();
  if (!device) {
    throw new Error('Device not found');
  }
  return device;
};
