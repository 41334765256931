/* eslint-disable no-lonely-if */
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { TimeIcon } from '@chakra-ui/icons';
import { Box, Text, Flex, Heading, Card, Icon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FormantNotification,
  FormantRobot,
} from '../../interfaces/formantNotification';
import { NotificationNavigation } from './NotificationNavigation';
import { useGeneralStore } from '../../stores/useGeneralStore';

dayjs.extend(isToday);

const NOTIFICATION_PAGE_SIZE = 3;

export function NotificationHistory() {
  const notifications = useGeneralStore.use.notifications();
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [displayedNotifications, setDisplayedNotifications] = useState<
    FormantNotification[]
  >([]);

  const getSystemName = (index: number) => {
    if (notifications.length === 0) {
      return '';
    }
    switch (notifications[index]?.robot.toUpperCase()) {
      case FormantRobot.COOKER:
        return '🍝 Pasta';
      case FormantRobot.SAUCE:
        return '🥫 Sauce';
      case FormantRobot.TOPPINGS:
        return '🧀 Toppings';
      default:
        return '';
    }
  };

  const getNotificationBody = (
    notification: FormantNotification,
    index: number,
  ) => {
    return (
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4" size="md" marginBottom={2} marginTop={5}>
            {`${getSystemName(index)} - ${notification?.name}`}
          </Heading>
          <Flex alignItems="center" gap={2}>
            <Icon as={TimeIcon} boxSize={4} />
            <Text>{dayjs(notification.timestamp).format('HH:mm')}</Text>
          </Flex>
        </Flex>
        <Text>{notification?.description}</Text>
      </Box>
    );
  };

  if (notifications.length === 0) {
    return (
      <Box>
        <Text>Aucune notification dans l&apos;historique</Text>
      </Box>
    );
  }

  useEffect(() => {
    if (notifications.length === 0) {
      return;
    }
    setDisplayedNotifications(
      notifications.slice(
        pageIndex * NOTIFICATION_PAGE_SIZE,
        pageIndex * NOTIFICATION_PAGE_SIZE + NOTIFICATION_PAGE_SIZE,
      ),
    );
  }, [pageIndex]);

  return (
    <Flex
      style={{ minHeight: 550 }}
      direction="column"
      justifyContent="space-between">
      <Box>
        {displayedNotifications.map((notification, i) => (
          <Card key={notification.id} marginBottom={4} p={4}>
            {getNotificationBody(notification, i)}
          </Card>
        ))}
      </Box>
      <NotificationNavigation
        index={pageIndex}
        setIndex={setPageIndex}
        notificationNumber={Math.ceil(
          notifications.length / NOTIFICATION_PAGE_SIZE,
        )}
      />
    </Flex>
  );
}
