/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Card,
  Box,
  Text,
  Checkbox,
  CheckboxGroup,
  Stack,
  Button,
} from '@chakra-ui/react';

import TemperatureReadout from '../../TemperatureReadout';
import {
  ITopping,
  ToppingContainerType,
  IToppingInformation,
  ToppingState,
} from '../../../interfaces/topping';

type ToppingsColumnCardsProps = {
  type: ToppingContainerType;
  temperature: number;
  toppings: ITopping[];
  // enabled: boolean;
  setSelectedTopping: (topping: IToppingInformation) => void;
  onOpen: () => void;
  setToppingsWithNewState: (toppings: ITopping[]) => void;
};

function ToppingsColumnCards({
  type,
  temperature,
  toppings,
  // enabled,
  setSelectedTopping,
  onOpen,
  setToppingsWithNewState,
}: ToppingsColumnCardsProps) {
  const updateSelectedTopping = (topping: ITopping) => {
    setSelectedTopping({
      topping,
      type,
    });
    onOpen();
  };

  const updateToppingsState = (updatingTopping: ITopping) => {
    const updatedToppings = toppings.map((topping) =>
      topping.id === updatingTopping.id ? updatingTopping : topping,
    );
    setToppingsWithNewState(updatedToppings);
  };

  return (
    <Card variant="outline" align="center">
      <Box p={4}>
        <Text
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          color={type === ToppingContainerType.COLD ? 'blue' : 'red'}>
          {type}
        </Text>
        <TemperatureReadout type={type} temperature={temperature} />
        <CheckboxGroup>
          <Stack spacing={[1]} direction={['column']}>
            {toppings.map((topping: ITopping) => (
              <Checkbox
                size="lg"
                mt="2"
                key={topping.id}
                isChecked={topping.state === ToppingState.ON}
                onChange={(e) =>
                  updateToppingsState({
                    ...topping,
                    state: e.target.checked
                      ? ToppingState.ON
                      : ToppingState.OFF,
                  })
                }>
                <Button onClick={() => updateSelectedTopping(topping)}>
                  {topping.name}
                </Button>
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </Box>
    </Card>
  );
}

export default ToppingsColumnCards;
