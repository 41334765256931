import { ISauceSystem } from '../../interfaces/sauce';
import { SystemStatus } from '../../interfaces/system';

const defaultSauceSystem: ISauceSystem = {
  sauceContainers: [],
  status: SystemStatus.IDLE,
  cleaningProgress: null,
};

export interface ISauceStore {
  sauceSystem: ISauceSystem | null;
  // formantSauceChannel: null;
}

export const initialSauceStore: ISauceStore = {
  sauceSystem: defaultSauceSystem,
  // formantSauceChannel: null,
};
