import { SystemStatus } from './system';

export interface ICleaningStatus {
  step: string | null;
  start: string | null;
  predictedEnd: string | null;
}

export interface IPastaSystem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leftPastaContainer: IPastaContainer | null;
  rightPastaContainer: IPastaContainer | null;
  currentDishesCooking: number;
  status: SystemStatus;
  cleaningStatus: ICleaningStatus;
  isAutoCookingActivated: boolean;
}
interface IPastaContainer {
  temperature: number;
}

export enum FormantPastaCommands {
  INIT_ROBOT = 'C6.start_cooker_initialization',
  START_BATCH = 'C6.start_batch_cooking',
  STOP_BATCH = 'C6.stop_batch_cooking',
  START_AUTOCOOKER = 'C6.update_autocooking',
  STOP_AUTOCOOKER = 'C6.stop_auto_cooking',
  UPDATE_AUTOCOOKER_STATUS = 'C6.update_autocooker_status',
  UPDATE_AUTOCOOKER_FREQUENCY = 'C6.update_autocooker_frequency',
  START_FAST_CLEANING = 'C6.start_fast_cleaning_cycle',
  START_MANUAL_CLEANING = 'C6.start_manual_cleaning',
  START_FULL_CLEANING = 'C6.start_full_cleaning',
  REBOOT = 'C6.reboot',
}

enum AutoCookerStatus {
  OFF = 0,
  ON = 1,
}

export type StartAutoCookerParameters = Record<string, never>;

export type UpdateAutoCookerStatusParameters = {
  auto_cooker_status: AutoCookerStatus;
};

export type UpdateAutoCookerPastaFrequencyParameters = {
  dish_frequency_per_hour: number;
};

export type RebootPastaSystemParameters = Record<string, never>;

export type BatchSizeParameters = {
  batch_size: number;
};
