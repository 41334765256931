/* eslint-disable no-case-declarations */
import dayjs from 'dayjs';

import { ICurrentValues } from '../../interfaces/formant';
import { IPastaStore } from '../stores';
import { getSystemStatus } from '../../interfaces/system';

export type PastaDispatchAction =
  | 'setPastaSystemStatus'
  | 'setPastaCookerInfo'
  | 'setPastaHeatingTowerStatus'
  | 'setPastaSamplingPortions'
  | 'setCleaningStatus'
  | 'setPastaCleaningStep'
  | 'setPastaCleaningStart'
  | 'setPastaCleaningPredictedEnd';

export interface IPastaDispatch {
  type: PastaDispatchAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export const pastaReducer = (
  state: IPastaStore,
  { type, payload }: IPastaDispatch,
) => {
  switch (type) {
    case 'setPastaSystemStatus':
      return { ...state, pastaSystem: payload };

    case 'setPastaCookerInfo':
      const leftPastaContainer = payload.find(
        (item: ICurrentValues) =>
          item.label === 'left_container_water_temperature',
      );
      const rightPastaContainer = payload.find(
        (item: ICurrentValues) =>
          item.label === 'right_container_water_temperature',
      );
      const currentDishesCooking = payload.find(
        (item: ICurrentValues) => item.label === 'currently_cooking_count',
      );
      const status = payload.find(
        (item: ICurrentValues) => item.label === 'cooker_status',
      );
      const autoCookerActivated = payload.find(
        (item: ICurrentValues) => item.label === 'auto_cooker_activated',
      );
      return {
        ...state,
        pastaSystem: {
          ...state.pastaSystem,
          status: getSystemStatus(status.value),
          leftPastaContainer: { temperature: leftPastaContainer?.value ?? 0 },
          rightPastaContainer: { temperature: rightPastaContainer?.value ?? 0 },
          currentDishesCooking: currentDishesCooking?.value ?? 0,
          isAutoCookingActivated: autoCookerActivated?.value ?? false,
        },
      };
    case 'setPastaHeatingTowerStatus':
      return { ...state, pastaHeatingTower: payload };
    case 'setPastaSamplingPortions':
      return { ...state, pastaSamplingPortions: payload };
    case 'setCleaningStatus':
      return {
        ...state,
        pastaSystem: { ...state.pastaSystem, cleaningStatus: payload },
      };
    case 'setPastaCleaningStep':
      return {
        ...state,
        pastaSystem: {
          ...state.pastaSystem,
          cleaningStatus: {
            ...state.pastaSystem?.cleaningStatus,
            step: payload,
          },
        },
      };
    case 'setPastaCleaningStart':
      return {
        ...state,
        pastaSystem: {
          ...state.pastaSystem,
          cleaningStatus: {
            ...state.pastaSystem?.cleaningStatus,
            start: payload,
          },
        },
      };
    case 'setPastaCleaningPredictedEnd':
      return {
        ...state,
        pastaSystem: {
          ...state.pastaSystem,
          cleaningStatus: {
            ...state.pastaSystem?.cleaningStatus,
            predictedEnd: payload,
          },
        },
      };
    default:
      return state;
  }
};
