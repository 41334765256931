import {
  Card,
  Box,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
} from '@chakra-ui/react';
import { BsDropletFill, BsDropletHalf } from 'react-icons/bs';
import LinearGauge from '../../LinearGauge';
import TemperatureReadout from '../../TemperatureReadout';
import { ISauceContainer } from '../../../interfaces/sauce';

const MAX_CONTAINER_CAPACITY = 12000;

function SauceMonitoringComponent({
  sauceContainer,
}: {
  sauceContainer: ISauceContainer;
}) {
  const {
    mass,
    smallPortionSize,
    bigPortionSize,
    sauceMargin = 0,
    temperature,
    enabled,
  } = sauceContainer;

  const getPortion = (portionSize: number | undefined) => {
    if (!mass || !portionSize) {
      return 0;
    }
    if (mass < sauceMargin) {
      return 0;
    }
    return Math.floor(((mass - sauceMargin) * 1000) / portionSize);
  };

  const getFillingPercentage = () => {
    return mass ? (mass / MAX_CONTAINER_CAPACITY) * 100 : 0;
  };

  return (
    <Card
      variant="outline"
      align="center"
      style={{
        opacity: enabled ? 1 : 0.3,
      }}>
      <Box padding={3}>
        <LinearGauge
          width={80}
          height={80}
          color="#EDF2F7"
          borderRadius={5}
          percentage={getFillingPercentage()}
        />
      </Box>
      <Box padding="2px">
        <TableContainer>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>{mass}</Td>
                <Td>kg</Td>
              </Tr>
              <Tr>
                <Td>{getPortion(bigPortionSize)}</Td>
                <Td>
                  <BsDropletFill />
                </Td>
              </Tr>
              <Tr>
                <Td>{getPortion(smallPortionSize)}</Td>
                <Td>
                  <BsDropletHalf />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box p={4}>
        <TemperatureReadout type="hot" temperature={temperature || 0} />
      </Box>
    </Card>
  );
}
export default SauceMonitoringComponent;
