import { Device } from '@formant/data-sdk';
import {
  FormantPastaCommands,
  BatchSizeParameters,
  UpdateAutoCookerPastaFrequencyParameters,
  UpdateAutoCookerStatusParameters,
} from '../interfaces/pasta';
import { sendCommandToFormant } from '../formant';

export const startFastCleaning = async (device: Device) => {
  await sendCommandToFormant(device, FormantPastaCommands.START_FAST_CLEANING);
};

export const startFullCleaning = async (device: Device) => {
  await sendCommandToFormant(device, FormantPastaCommands.START_FULL_CLEANING);
};

export const startManualCleaning = async (device: Device) => {
  await sendCommandToFormant(
    device,
    FormantPastaCommands.START_MANUAL_CLEANING,
  );
};

export const initilizePastaSystem = async (device: Device) => {
  await sendCommandToFormant(device, FormantPastaCommands.INIT_ROBOT);
};

export const rebootPastaSystem = async (device: Device) => {
  await sendCommandToFormant(device, FormantPastaCommands.REBOOT);
};

export const sendPastaDishesBatch = async (
  device: Device,
  parameters: BatchSizeParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantPastaCommands.START_BATCH,
    parameters,
  );
};

export const updatePastaAutoCookerFrequency = async (
  device: Device,
  parameters: UpdateAutoCookerPastaFrequencyParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantPastaCommands.UPDATE_AUTOCOOKER_FREQUENCY,
    parameters,
  );
};

export const updatePastaAutoCookerStatus = async (
  device: Device,
  parameters: UpdateAutoCookerStatusParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantPastaCommands.UPDATE_AUTOCOOKER_STATUS,
    parameters,
  );
};
