import { Progress } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const PROGRESS_PRECISION_IN_SECOND = 1000;

export function SauceCleaningProgress({
  durationInMinutes,
  minutesSinceCleaningStarted,
}: {
  durationInMinutes: number;
  minutesSinceCleaningStarted: number | null;
}) {
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);

  const startProgressBar = (minutesSinceStart: number) => {
    setShowProgressBar(true);
    setProgress(0);
    setTimeout(() => {
      let percentage = minutesSinceStart;
      const interval = setInterval(() => {
        if (percentage >= 100) {
          clearInterval(interval);
          setShowProgressBar(false);
          setProgress(0);
          try {
            localStorage.removeItem('cleaningStart');
          } catch (error) {
            console.log('startProgressBar error', error);
          }
        } else {
          setProgress(percentage);
          percentage += durationInMinutes / PROGRESS_PRECISION_IN_SECOND;
        }
      }, PROGRESS_PRECISION_IN_SECOND);
    }, durationInMinutes);
  };

  useEffect(() => {
    if (minutesSinceCleaningStarted !== null) {
      startProgressBar(minutesSinceCleaningStarted);
    }
  }, []);

  if (!showProgressBar) {
    return null;
  }

  return <Progress size="lg" value={progress} borderRadius={5} />;
}
