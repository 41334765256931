import { Flex, Grid } from '@chakra-ui/react';
import { ToppingsSystem } from '../components/Service/ToppingsSystem/ToppingsSystem';
import { SauceSystem } from '../components/Service/SauceSystem/SauceSystem';
import { PastaSystem } from '../components/Service/PastaSystem/PastaSystem';
import { useInterval } from '../hooks';
import { useStore } from '../store';
import { parseFormantTelemetry } from '../utils';

const POLLING_FREQUENCY = 5000;

function ServicePage() {
  const { dispatch, store } = useStore();
  const { formantDevice, tag } = store;

  useInterval(() => {
    const getTelemetry = async () => {
      if (formantDevice) {
        const telemetry = await formantDevice.getLatestTelemetry();
        if (telemetry) {
          parseFormantTelemetry(dispatch, telemetry, tag);
        }
      }
    };
    getTelemetry();
  }, POLLING_FREQUENCY);

  return (
    <Flex direction="column">
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <PastaSystem />
        <SauceSystem />
      </Grid>
      <ToppingsSystem />
    </Flex>
  );
}

export default ServicePage;
