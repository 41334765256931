import { Box, Flex } from '@chakra-ui/react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

function Tabs() {
  const { pathname } = useLocation();

  const isTabSelected = (tabName: string) => {
    if (tabName === 'start') {
      return pathname === '/' || pathname === '/start';
    }
    return pathname.includes(tabName);
  };

  const selectedTabStyle = {
    fontSize: '25px',
    fontWeight: 'bold',
    marginRight: '10px',
    backgroundColor: '#CBD5E0',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    padding: '5px 10px',
  };

  const defaultTabStyle = {
    fontSize: '25px',
    fontWeight: 'bold',
    marginRight: '10px',
    padding: '5px 10px',
  };

  return (
    <Flex direction="column" p={4} bg="white" style={{ height: '100vh' }}>
      <Flex alignItems="center">
        <NavLink to="/start">
          <div
            style={isTabSelected('start') ? selectedTabStyle : defaultTabStyle}>
            Démarrage
          </div>
        </NavLink>
        <NavLink to="/service">
          <div
            style={
              isTabSelected('service') ? selectedTabStyle : defaultTabStyle
            }>
            Service
          </div>
        </NavLink>
        <NavLink to="/cleaning">
          <div
            style={
              isTabSelected('cleaning') ? selectedTabStyle : defaultTabStyle
            }>
            Nettoyage
          </div>
        </NavLink>
      </Flex>
      <Box
        flex={1}
        padding={4}
        background="#CBD5E0"
        style={{
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
        }}>
        <Outlet />
      </Box>
    </Flex>
  );
}

export default Tabs;
