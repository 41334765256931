/* eslint-disable @typescript-eslint/no-explicit-any */
import { Device, INumericSetEntry } from '@formant/data-sdk';

import {
  ToppingState,
  RobotToppingContainerType,
  ToppingContainerType,
  getToppingName,
} from '../interfaces/topping';
import { IStreamData } from '../interfaces/formant';

function getToppingState(state: number) {
  switch (state) {
    case 0:
      return ToppingState.OFF;
    case 1:
      return ToppingState.ON;
    case 2:
      return ToppingState.EMPTY;
    case 3:
      return ToppingState.ERROR;
    default:
      return ToppingState.ERROR;
  }
}

export function getToppingRobotStatusFromState(value: ToppingState) {
  switch (value) {
    case ToppingState.OFF:
      return false;
    case ToppingState.ON:
      return true;
    case ToppingState.EMPTY:
      return true;
    case ToppingState.ERROR:
      return true;
    default:
      return true;
  }
}

export const getToppingTypeFromRobotToppingType = (
  type: RobotToppingContainerType,
) => {
  switch (type) {
    case RobotToppingContainerType.HOT:
      return ToppingContainerType.HOT;
    case RobotToppingContainerType.COLD:
      return ToppingContainerType.COLD;
    default:
      throw new Error('Unknown topping type');
  }
};

export const getRobotToppingTypeFromToppingType = (
  type: ToppingContainerType,
) => {
  switch (type) {
    case ToppingContainerType.HOT:
      return RobotToppingContainerType.HOT;
    case ToppingContainerType.COLD:
      return RobotToppingContainerType.COLD;
    default:
      throw new Error('Unknown topping type');
  }
};

const getContainerInformations = (
  latestTelemetry: any,
  type: RobotToppingContainerType,
) => {
  const containersStatus = latestTelemetry.find(
    (data: IStreamData) =>
      data.streamName === `Nation_T2.${type}_toppings_containers_status`,
  );
  const toppingsContainers = containersStatus.currentValue;
  const toppingsContainersTemperature = latestTelemetry.find(
    (data: IStreamData) =>
      data.streamName === 'Nation_T2.toppings_temperatures',
  );
  const toppingsContainerTemperature =
    toppingsContainersTemperature.currentValue[
      type === RobotToppingContainerType.COLD ? 0 : 1
    ].value;

  const toppings = toppingsContainers.map(
    (topping: INumericSetEntry, index: number) => ({
      id: index,
      name: getToppingName(index + 1),
      state: getToppingState(topping.value),
    }),
  );

  return {
    type: getToppingTypeFromRobotToppingType(type),
    temperature: toppingsContainerTemperature,
    toppings,
    enabled: false,
  };
};

export const initToppingContainerData = async (device: Device) => {
  const latestTelemetry = await device.getLatestTelemetry();

  const hotToppings = getContainerInformations(
    latestTelemetry,
    RobotToppingContainerType.HOT,
  );
  const coldToppings = getContainerInformations(
    latestTelemetry,
    RobotToppingContainerType.COLD,
  );

  return { coldToppings, hotToppings };
};
