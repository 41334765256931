import { Flex, Grid, useInterval } from '@chakra-ui/react';

import { PastaCleaning } from '../components/Cleaning/PastaCleaning';
import { SauceCleaning } from '../components/Cleaning/SauceCleaning';
// import { ToppingsCleaning } from '../components/Cleaning/ToppingsCleaning';
import { useStore } from '../store';
import { parseFormantTelemetry } from '../utils';

const POLLING_FREQUENCY = 5000;

function CleaningPage() {
  const { dispatch, store } = useStore();
  const { formantDevice } = store;

  useInterval(() => {
    const getTelemetry = async () => {
      if (formantDevice) {
        const telemetry = await formantDevice.getLatestTelemetry();
        if (telemetry) {
          parseFormantTelemetry(dispatch, telemetry);
        }
      }
    };
    getTelemetry();
  }, POLLING_FREQUENCY);
  return (
    <Flex direction="column">
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <PastaCleaning />
        <SauceCleaning />
        {/* <ToppingsCleaning /> */}
      </Grid>
    </Flex>
  );
}

export default CleaningPage;
