export enum FormantNotificationType {
  INFO = 'INFO',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  CRITICAL_ERROR = 'CRITICAL_ERROR',
  SOLVABLE_ERROR = 'SOLVABLE_ERROR',
  ERROR = 'ERROR',
}

export enum FormantNotificationStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  ANSWERED = 'ANSWERED',
  RESOLVED = 'RESOLVED',
}

export enum FormantRobot {
  COOKER = 'COOKER',
  SAUCE = 'SAUCES',
  TOPPINGS = 'TOPPINGS',
}

export type RequiredActionChoice = {
  name: string;
  command: string;
};

export type RequiredAction = {
  notificationId: number;
  id?: number;
  description: string;
  choices: RequiredActionChoice[];
};

export type FormantNotification = {
  robotNotificationId: number;
  id?: number;
  name: string;
  description: string;
  status: FormantNotificationStatus;
  type: FormantNotificationType;
  timestamp: string;
  robot: FormantRobot;
  requiredActions: RequiredAction[];
  restaurantLocation: string;
};
