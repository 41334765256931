import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Input,
  ModalFooter,
  FormControl,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { dispatchUpdateToppingName, useStore } from '../../../store';
import {
  ITopping,
  ToppingContainerType,
  IToppingInformation,
} from '../../../interfaces/topping';
import { storeToppingsNames } from '../../../utils';
import { getRobotToppingTypeFromToppingType } from '../../../formant';

type UpdateToppingsNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedTopping: IToppingInformation | null;
  setColdToppingsWithNewState: React.Dispatch<React.SetStateAction<ITopping[]>>;
  setHotToppingsWithNewState: React.Dispatch<React.SetStateAction<ITopping[]>>;
};

export function UpdateToppingsNameModal({
  isOpen,
  onClose,
  selectedTopping,
  setColdToppingsWithNewState,
  setHotToppingsWithNewState,
}: UpdateToppingsNameModalProps) {
  const {
    dispatch,
    store: { toppingsSystem },
  } = useStore();
  const [newToppingName, setNewToppingName] = useState('');

  const saveToppingsName = (
    topping: ITopping | undefined,
    toppingType: ToppingContainerType | undefined,
  ) => {
    if (!toppingType || !topping) {
      return;
    }
    const updatingToppingContainersItems =
      toppingType === ToppingContainerType.COLD
        ? toppingsSystem?.coldToppingsContainer?.toppings
        : toppingsSystem?.hotToppingsContainer?.toppings;

    if (updatingToppingContainersItems) {
      updatingToppingContainersItems[topping.id].name = newToppingName;
      storeToppingsNames(
        updatingToppingContainersItems,
        getRobotToppingTypeFromToppingType(toppingType),
      );
      dispatch(
        dispatchUpdateToppingName(newToppingName, topping.id, toppingType),
      );
      if (toppingType === ToppingContainerType.COLD) {
        setColdToppingsWithNewState(updatingToppingContainersItems);
      } else {
        setHotToppingsWithNewState(updatingToppingContainersItems);
      }
    }

    dispatch(
      dispatchUpdateToppingName(newToppingName, topping.id, toppingType),
    );

    setNewToppingName('');
    onClose();
  };

  useEffect(() => {
    if (selectedTopping) {
      setNewToppingName(selectedTopping.topping.name);
    }
  }, [selectedTopping]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update the topping name</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>New name</FormLabel>
            <Input
              placeholder="name"
              value={newToppingName}
              onChange={(e) => setNewToppingName(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              saveToppingsName(selectedTopping?.topping, selectedTopping?.type);
            }}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
