import { useContext } from 'react';
import { StoreStateContext, StoreDispatchContext } from './context';

export const useStore = () => {
  const store = useContext(StoreStateContext);
  const dispatch = useContext(StoreDispatchContext);
  if (!store || !dispatch) {
    throw new Error('useStore must be used within a StoreContext');
  }

  return { store, dispatch };
};
