import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';

import { SauceSystem } from '../components/Initialisation/SauceSystem/SauceSystem';
import { PastaSystem } from '../components/Initialisation/PastaSystem/PastaSystem';
import { ToppingsSystem } from '../components/Initialisation/ToppingsSystem/ToppingsSystem';
import { useStore } from '../store';
import { parseFormantTelemetry } from '../utils';
import { useInterval } from '../hooks';

const POLLING_FREQUENCY = 5000;

function InitPage() {
  const { store, dispatch } = useStore();
  const { formantDevice, tag } = store;

  const getTelemetry = async () => {
    if (formantDevice) {
      const telemetry = await formantDevice.getLatestTelemetry();
      if (telemetry) {
        parseFormantTelemetry(dispatch, telemetry, tag);
      }
    }
  };

  useEffect(() => {
    getTelemetry();
  }, [formantDevice]);

  useInterval(() => {
    getTelemetry();
  }, POLLING_FREQUENCY);

  return (
    <Flex>
      <Flex direction="column" flex="1" marginRight={4}>
        <PastaSystem />
        <ToppingsSystem />
      </Flex>
      <SauceSystem />
    </Flex>
  );
}

export default InitPage;
