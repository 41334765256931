import { Card, Grid, Heading } from '@chakra-ui/react';
import { ISauceContainer } from '../../../interfaces/sauce';

import { useStore } from '../../../store/useStore';
import SauceMonitoring from './SauceMonitoring';

export function SauceSystem() {
  const {
    store: { sauceSystem },
  } = useStore();
  const { sauceContainers } = sauceSystem || {};

  return (
    <Card bg="white" p={4} mb={4}>
      <Heading as="h3" size="lg" className="component-heading-small" mb={2}>
        Sauces
      </Heading>
      <Grid templateColumns="1fr 1fr 1fr 1fr" gap={4}>
        {sauceContainers &&
          sauceContainers.map((sauceContainer: ISauceContainer, index) => (
            <SauceMonitoring
              key={sauceContainer.id || index}
              sauceContainer={sauceContainer}
            />
          ))}
      </Grid>
    </Card>
  );
}
