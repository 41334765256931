import { Device } from '@formant/data-sdk';
import { ISauceStore } from './sauce';
import { IToppingsStore } from './toppings';
import { IPastaStore } from './pasta';
import { FormantNotification } from '../../interfaces/formantNotification';

export interface IMainStore {
  formantDevice: Device | null;
  tag: string;
  notifications: FormantNotification[];
  restaurantLocation: string | null;
}

export const initialMainStore: IMainStore = {
  formantDevice: null,
  notifications: [],
  tag: '',
  restaurantLocation: null,
};

export type IStore = IMainStore & ISauceStore & IToppingsStore & IPastaStore;

export * from './sauce';
export * from './toppings';
export * from './pasta';
