import {
  Box,
  Card,
  CardBody,
  Grid,
  Heading,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import StatusBadge from '../../StatusBadge';
import { useStore } from '../../../store';
import { AutoCooking } from './AutoCooking';
import { BatchCooking } from './BatchCooking';

export function PastaSystem() {
  const {
    store: { pastaSystem },
  } = useStore();

  return (
    <Card bg="white" p={4} mb={4}>
      <Grid templateColumns="1fr 1fr" gap={4}>
        <Box p={4}>
          <Stack spacing={4}>
            <Heading
              as="h3"
              size="lg"
              className="component-heading-small"
              mb={2}>
              Pasta
            </Heading>
            <StatusBadge status={pastaSystem?.status} />

            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box>
                    <Grid templateColumns="1fr 1fr" gap={4} alignItems="center">
                      <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                        Pates cuites
                      </Text>
                      <Text fontSize="s" textAlign="center">
                        Coming soon
                      </Text>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid templateColumns="1fr 1fr" gap={4} alignItems="center">
                      <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                        Pates en cuisson
                      </Text>
                      <Text fontWeight="bold" fontSize="4xl" textAlign="center">
                        {pastaSystem?.currentDishesCooking}
                      </Text>
                    </Grid>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Stack>
        </Box>
        <Box>
          <Stack spacing={3.5}>
            <AutoCooking />
            <BatchCooking />
          </Stack>
        </Box>
      </Grid>
    </Card>
  );
}
