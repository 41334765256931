import { ICurrentValues } from '../../interfaces/formant';
import { ISauceContainer } from '../../interfaces/sauce';
import { getSauceSystemStatus } from '../../interfaces/system';
import { ISauceStore } from '../stores';

export type SauceDispatchAction =
  | 'setSauceSystem'
  | 'setSauceContainers'
  | 'setContainersTemperature'
  | 'updateSauceContainer'
  | 'setSauceContainersSmallPortions'
  | 'setSauceContainersBigPortions'
  | 'setSauceContainersTemperature'
  | 'setSauceContainersStock'
  | 'setSauceContainersState'
  | 'setSauceContainersHeaterState'
  | 'setSauceCleaningProgress'
  | 'setSauceSystemStatus';
// | 'setFormantSauceChannel';

export interface ISauceDispatch {
  type: SauceDispatchAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export const sauceReducer = (
  state: ISauceStore,
  { type, payload }: ISauceDispatch,
) => {
  switch (type) {
    case 'setSauceSystem':
      return { ...state, sauceSystem: payload };
    case 'setSauceContainers': {
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: [...payload],
        },
      };
    }
    case 'setSauceContainersSmallPortions': {
      const updatedContainers = payload.map(
        (containerPortion: ICurrentValues) => {
          const foundContainer = state.sauceSystem?.sauceContainers.find(
            (container: ISauceContainer) =>
              Number(containerPortion?.label) === container?.id,
          );
          if (!foundContainer) {
            return {
              id: Number(containerPortion?.label),
              name: `Cuve ${Number(containerPortion?.label) + 1}`,
              smallPortionSize: containerPortion.value,
            };
          }
          return {
            ...foundContainer,
            smallPortionSize: containerPortion.value,
          };
        },
      );
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: updatedContainers,
        },
      };
    }
    case 'setSauceContainersBigPortions': {
      const updatedContainers = payload.map(
        (containerPortion: ICurrentValues) => {
          const foundContainer = state.sauceSystem?.sauceContainers.find(
            (container: ISauceContainer) =>
              Number(containerPortion?.label) === container?.id,
          );
          if (!foundContainer) {
            return {
              id: Number(containerPortion?.label),
              name: `Cuve ${Number(containerPortion?.label) + 1}`,
              bigPortionSize: containerPortion.value,
            };
          }
          return {
            ...foundContainer,
            bigPortionSize: containerPortion.value,
          };
        },
      );
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: updatedContainers,
        },
      };
    }
    case 'setSauceContainersTemperature': {
      const updatedContainers = payload.map(
        (containerTemperature: ICurrentValues) => {
          const foundContainer = state.sauceSystem?.sauceContainers.find(
            (container: ISauceContainer) =>
              Number(containerTemperature?.label) === container?.id,
          );
          if (!foundContainer) {
            return {
              id: Number(containerTemperature?.label),
              name: `Cuve ${Number(containerTemperature?.label) + 1}`,
              temperature: containerTemperature.value,
            };
          }
          return {
            ...foundContainer,
            temperature: containerTemperature.value,
          };
        },
      );
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: updatedContainers,
        },
      };
    }
    case 'setSauceContainersStock': {
      const updatedContainers = payload.map(
        (containerStock: ICurrentValues) => {
          const foundContainer = state.sauceSystem?.sauceContainers.find(
            (container: ISauceContainer) =>
              Number(containerStock?.label) === container?.id,
          );
          if (!foundContainer) {
            return {
              id: Number(containerStock?.label),
              name: `Cuve ${Number(containerStock?.label) + 1}`,
              mass: containerStock.value,
            };
          }
          return {
            ...foundContainer,
            mass: containerStock.value,
          };
        },
      );
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: updatedContainers,
        },
      };
    }
    case 'setSauceContainersState': {
      const updatedContainers = payload.map(
        (containerState: ICurrentValues) => {
          const foundContainer = state.sauceSystem?.sauceContainers.find(
            (container: ISauceContainer) =>
              Number(containerState?.label) === container?.id,
          );
          if (!foundContainer) {
            return {
              id: Number(containerState?.label),
              name: `Cuve ${Number(containerState?.label) + 1}`,
              enabled: Number(containerState.value),
            };
          }
          return {
            ...foundContainer,
            enabled: Number(containerState.value),
          };
        },
      );
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: updatedContainers,
        },
      };
    }
    case 'setSauceContainersHeaterState': {
      const updatedContainers = payload.map(
        (containerHeaterState: ICurrentValues) => {
          const foundContainer = state.sauceSystem?.sauceContainers.find(
            (container: ISauceContainer) =>
              Number(containerHeaterState?.label) === container?.id,
          );
          if (!foundContainer) {
            return {
              id: Number(containerHeaterState?.label),
              name: `Cuve ${Number(containerHeaterState?.label) + 1}`,
              isHeating: Number(containerHeaterState.value),
            };
          }
          return {
            ...foundContainer,
            isHeating: Number(containerHeaterState.value),
          };
        },
      );
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          sauceContainers: updatedContainers,
        },
      };
    }
    case 'setSauceCleaningProgress': {
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          cleaningProgress: payload,
        },
      };
    }
    case 'setSauceSystemStatus': {
      console.log('setSauceSystemStatus', getSauceSystemStatus(payload));
      return {
        ...state,
        sauceSystem: {
          ...state.sauceSystem,
          status: getSauceSystemStatus(payload),
        },
      };
    }
    default:
      return state;
  }
};
