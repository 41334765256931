import { Flex, Badge } from '@chakra-ui/react';
import {
  FaThermometerEmpty,
  FaThermometerHalf,
  FaThermometerFull,
} from 'react-icons/fa';

type TemperatureReadoutProps = {
  type: string;
  temperature: number;
};

function TemperatureReadout({ type, temperature }: TemperatureReadoutProps) {
  let colorScheme;
  let icon;
  if (type === 'hot') {
    if (temperature < 60) {
      colorScheme = 'blue';
      icon = <FaThermometerEmpty size={24} />;
    } else if (temperature < 80) {
      colorScheme = 'orange';
      icon = <FaThermometerHalf size={24} />;
    } else {
      colorScheme = 'red';
      icon = <FaThermometerFull size={24} />;
    }
  } else if (type === 'cold') {
    if (temperature > 6) {
      colorScheme = 'red';
      icon = <FaThermometerFull size={24} />;
    } else {
      colorScheme = 'blue';
      icon = <FaThermometerEmpty size={24} />;
    }
  }

  return (
    <Flex align="center" justifyContent="center">
      <Badge
        variant="subtle"
        colorScheme={colorScheme}
        textAlign="center"
        fontSize="xl"
        p={2}
        m={0}
        borderRadius="7px">
        <Flex align="center">
          <span>{temperature.toFixed(0)}°C</span>
          {icon}
        </Flex>
      </Badge>
    </Flex>
  );
}

export default TemperatureReadout;
