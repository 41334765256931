import { ITopping, RobotToppingContainerType } from '../interfaces/topping';

export const storeToppingsNames = (
  toppings: ITopping[],
  type: RobotToppingContainerType,
) => {
  const toppingsNames = toppings.map((topping: ITopping) => {
    return { id: topping.id, name: topping.name };
  });
  try {
    localStorage.setItem(`${type}ToppingsNames`, JSON.stringify(toppingsNames));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(error);
  }
};

export const retreiveToppingsNames = (
  type: RobotToppingContainerType,
  toppings: ITopping[],
) => {
  let updatedToppingsWithNames = [...toppings];
  try {
    const toppingsWithNames = localStorage.getItem(`${type}ToppingsNames`);
    if (toppingsWithNames) {
      const parsedToppingsWithNames = JSON.parse(toppingsWithNames);
      updatedToppingsWithNames = toppings.map((topping: ITopping) => {
        const foundTopping = parsedToppingsWithNames.find(
          (toppingName: ITopping) => toppingName.id === topping.id,
        );
        if (!foundTopping) return topping;
        return {
          ...topping,
          ...foundTopping,
        };
      });
    }

    return updatedToppingsWithNames;
  } catch (error) {
    return updatedToppingsWithNames;
  }
};
