import { getTagFromDeviceName } from '../../utils/formant';
import { IMainStore } from '../stores';
import { IPastaDispatch } from './pasta';
import { ISauceDispatch } from './sauce';
import { IToppingsDispatch } from './toppings';

export type DispatchAction =
  | 'setFormantDevice'
  | 'setNotifications'
  | 'setRestaurantLocation';

export interface IMainDispatch {
  type: DispatchAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export const mainReducer = (
  state: IMainStore,
  { type, payload }: IMainDispatch,
) => {
  switch (type) {
    case 'setFormantDevice': {
      const tag = getTagFromDeviceName(payload.name);
      return { ...state, formantDevice: payload, tag };
    }
    case 'setNotifications':
      return { ...state, notifications: payload };
    case 'setRestaurantLocation':
      return { ...state, restaurantLocation: payload };
    default:
      return state;
  }
};

export type IDispatch =
  | IMainDispatch
  | ISauceDispatch
  | IToppingsDispatch
  | IPastaDispatch;

export * from './sauce';
export * from './toppings';
export * from './pasta';
