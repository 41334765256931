import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Flex,
  SimpleGrid,
  Heading,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import ResetButton from '../ResetButton';
import ToppingsColumnCard from './ToppingsColumnCard';
import { useStore } from '../../../store/useStore';
import {
  ToppingContainerType,
  IToppingInformation,
  ITopping,
  ToppingState,
} from '../../../interfaces/topping';
import { UpdateToppingsNameModal } from './UpdateToppingsNameModal';
import { rebootToppingsSystem, updateToppingsStatus } from '../../../utils';
import { getToppingRobotStatusFromState } from '../../../formant';

export function ToppingsSystem() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTopping, setSelectedTopping] =
    useState<IToppingInformation | null>(null);
  const [coldToppingsWithNewState, setColdToppingsWithNewState] = useState<
    ITopping[]
  >([]);
  const [hotToppingsWithNewState, setHotToppingsWithNewState] = useState<
    ITopping[]
  >([]);

  const {
    store: { formantDevice, toppingsSystem },
  } = useStore();
  const { coldToppingsContainer, hotToppingsContainer } = toppingsSystem || {};

  const handleToppingsStartButtonClick = () => {
    if (!formantDevice) return;
    updateToppingsStatus(formantDevice, {
      cold_toppings_status: coldToppingsWithNewState.map((topping) => ({
        index: topping.id,
        status: getToppingRobotStatusFromState(topping.state),
      })),
      hot_toppings_status: hotToppingsWithNewState.map((topping) => ({
        index: topping.id,
        status: getToppingRobotStatusFromState(topping.state),
      })),
    });
  };

  useEffect(() => {
    if (
      coldToppingsWithNewState.length === 0 &&
      coldToppingsContainer?.toppings
    ) {
      const initialCheckboxStates = coldToppingsContainer.toppings.map(
        (topping: ITopping) => ({
          ...topping,
          state:
            topping.state === ToppingState.OFF
              ? ToppingState.OFF
              : ToppingState.ON,
        }),
      );
      setColdToppingsWithNewState(initialCheckboxStates);
    }
  }, [coldToppingsContainer]);

  useEffect(() => {
    if (
      hotToppingsWithNewState.length === 0 &&
      hotToppingsContainer?.toppings
    ) {
      const initialCheckboxStates = hotToppingsContainer.toppings.map(
        (topping: ITopping) => ({
          ...topping,
          state:
            topping.state === ToppingState.OFF
              ? ToppingState.OFF
              : ToppingState.ON,
        }),
      );
      setHotToppingsWithNewState(initialCheckboxStates);
    }
  }, [hotToppingsContainer]);

  const handleRebootToppingsSystem = async () => {
    if (formantDevice) {
      rebootToppingsSystem(formantDevice);
    }
  };

  return (
    <>
      <Card bg="white" p={4}>
        <Flex justifyContent="space-between">
          <Heading as="h3" size="lg" className="component-heading-small" mb={2}>
            Toppings
          </Heading>
          <SimpleGrid columns={2} spacing={2}>
            <Button
              fontSize="2xl"
              size="lg"
              colorScheme="blue"
              onClick={() => handleToppingsStartButtonClick()}>
              Démarrer
            </Button>
            <ResetButton
              name="toppings"
              sendResetCommand={() => handleRebootToppingsSystem()}
            />
          </SimpleGrid>
        </Flex>
        <SimpleGrid
          spacing="10px"
          columns={3}
          rowGap={2}
          mt="5"
          templateColumns="repeat(2, 1fr)">
          <Box>
            {hotToppingsContainer && (
              <ToppingsColumnCard
                type={ToppingContainerType.HOT}
                temperature={hotToppingsContainer?.temperature}
                toppings={hotToppingsWithNewState}
                // enabled={hotToppingsContainer?.enabled}
                setSelectedTopping={setSelectedTopping}
                onOpen={onOpen}
                setToppingsWithNewState={setHotToppingsWithNewState}
              />
            )}
          </Box>
          <Box>
            {coldToppingsContainer && (
              <ToppingsColumnCard
                type={ToppingContainerType.COLD}
                temperature={coldToppingsContainer?.temperature}
                toppings={coldToppingsWithNewState}
                // enabled={coldToppingsContainer?.enabled}
                setSelectedTopping={setSelectedTopping}
                onOpen={onOpen}
                setToppingsWithNewState={setColdToppingsWithNewState}
              />
            )}
          </Box>
        </SimpleGrid>
      </Card>
      <UpdateToppingsNameModal
        isOpen={isOpen}
        onClose={onClose}
        selectedTopping={selectedTopping}
        setColdToppingsWithNewState={setColdToppingsWithNewState}
        setHotToppingsWithNewState={setHotToppingsWithNewState}
      />
    </>
  );
}
