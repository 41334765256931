import { BellIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  IconButton,
  Text,
  Box,
  keyframes,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  FormantNotification,
  FormantNotificationType,
} from '../../interfaces/formantNotification';
import { useGeneralStore } from '../../stores/useGeneralStore';

type NotificationButtonProps = {
  onOpen: () => void;
  activeNotifications: FormantNotification[];
};

const style = {
  NotificationBell: {
    position: 'absolute' as const,
    right: 15,
    top: 8,
  },
};

const tiltShaking = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

const animation = `${tiltShaking} 0.2s ease-in-out infinite`;

export function NotificationButton({
  onOpen,
  activeNotifications,
}: NotificationButtonProps) {
  const playing = useGeneralStore.use.playingSound();

  const getButtonColor = () => {
    if (activeNotifications.length === 0) {
      return 'gray';
    }
    if (
      activeNotifications.some(
        (notification) =>
          notification.type === FormantNotificationType.CRITICAL_ERROR ||
          notification.type === FormantNotificationType.SOLVABLE_ERROR,
      )
    ) {
      return 'red';
    }
    if (
      activeNotifications.some(
        (notification) =>
          notification.type === FormantNotificationType.ACTION_REQUIRED,
      )
    ) {
      return 'blue';
    }
    if (
      activeNotifications.some(
        (notification) => notification.type === FormantNotificationType.INFO,
      )
    ) {
      return 'yellow';
    }
    return 'green';
  };

  return (
    <Flex
      style={style.NotificationBell}
      gap={3}
      justifyContent="center"
      alignItems="center">
      <Heading as="h4" size="md">
        {activeNotifications.length}
      </Heading>
      <Box as={motion.div} animation={playing ? animation : undefined}>
        <IconButton
          colorScheme={getButtonColor()}
          aria-label="Notification button"
          onClick={onOpen}
          size="lg"
          icon={<BellIcon boxSize={7} />}
        />
      </Box>
    </Flex>
  );
}
