import { ICurrentValues } from '../../interfaces/formant';
import { IToppingSystem } from '../../interfaces/topping';
import { IDispatch } from '../reducers';

export const dispatchToppingsSystem = (
  toppingsSystem: IToppingSystem,
): IDispatch => ({
  type: 'setToppingsSystem',
  payload: toppingsSystem,
});

export const dispatchUpdateToppingName = (
  toppingName: string,
  toppingIndex: number | null,
  toppingType: string,
): IDispatch => ({
  type: 'updateToppingName',
  payload: { toppingName, toppingIndex, toppingType },
});

export const dispatchToppingsContainersTemperature = (
  temperatures: ICurrentValues[],
): IDispatch => ({
  type: 'updateToppingsContainersTemperature',
  payload: temperatures,
});

export const dispatchColdToppingsContainersStatuses = (
  statuses: ICurrentValues[],
): IDispatch => ({
  type: 'updateColdToppingsContainersStatuses',
  payload: statuses,
});

export const dispatchHotToppingsContainersStatuses = (
  statuses: ICurrentValues[],
): IDispatch => ({
  type: 'updateHotToppingsContainersStatuses',
  payload: statuses,
});
