import { FormantDeviceName, RestaurantTag } from '../interfaces/formant';

export const getTagFromDeviceName = (deviceName: string) => {
  let currentRestaurantName = null;
  Object.entries(FormantDeviceName).forEach(
    ([restaurantName, formantDeviceNameValue]) => {
      if (deviceName === formantDeviceNameValue) {
        currentRestaurantName = restaurantName;
      }
    },
  );
  if (!currentRestaurantName) {
    return '';
  }

  return RestaurantTag[currentRestaurantName];
};
