type LinearGaugeProps = {
  width: number;
  height: number;
  color: string;
  percentage: number;
  borderRadius: number;
};

function LinearGauge({
  width,
  height,
  color,
  percentage = 0,
  borderRadius = 0,
}: LinearGaugeProps) {
  let fillColor = 'green';

  if (percentage < 10) {
    fillColor = 'red';
  } else if (percentage < 30) {
    fillColor = 'orange';
  }

  const outerRadius = borderRadius + 1; // add 1 to account for the border width
  const innerRadius = Math.max(borderRadius - 1, 0); // subtract 1 to account for the border thickness

  const style = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: color,
    position: 'relative' as const,
    border: '1px solid black',
    borderRadius: `${outerRadius}px`,
  };

  const fillStyle = {
    position: 'absolute' as const,
    bottom: 0,
    left: 0,
    right: 0,
    height: `${percentage}%`,
    backgroundColor: fillColor,
    borderRadius: `${innerRadius}px`,
  };

  return (
    <div style={style}>
      <div style={fillStyle} />
    </div>
  );
}

export default LinearGauge;
