import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import App from './App';
import { StoreProvider } from './store';

const container = document.getElementById('root');

if (!container) {
  throw new Error('No root element found');
}

const root = createRoot(container);

const theme = extendTheme({
  colors: {
    gray: {
      300: '#CBD5E0',
    },
  },
});

root.render(
  <React.StrictMode>
    <StoreProvider>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
);
