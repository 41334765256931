import { useEffect, useState } from 'react';
import { useGeneralStore } from '../stores/useGeneralStore';

export const useAudio = (url: string) => {
  const [audio] = useState(new Audio(url));
  const setPlaying = useGeneralStore.use.setPlayingSound();
  const playing = useGeneralStore.use.playingSound();

  const updatePLayState = (state: boolean) => {
    setPlaying(state);
  };

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (playing) {
      audio.play();
      interval = setInterval(() => {
        audio.play();
      }, 30000);
    } else {
      audio.pause();
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [playing]);

  // useEffect(() => {
  //   audio.addEventListener('ended', () => setPlaying(false));
  //   return () => {
  //     audio.removeEventListener('ended', () => setPlaying(false));
  //   };
  // }, []);

  return { updatePLayState };
};
