import { IToppingSystem } from '../../interfaces/topping';

const defaultToppingsSystem: IToppingSystem = {
  coldToppingsContainer: null,
  hotToppingsContainer: null,
  status: null,
};

export interface IToppingsStore {
  toppingsSystem: IToppingSystem;
  // formantToppingsChannel: null;
}

export const initialToppingsStore: IToppingsStore = {
  toppingsSystem: defaultToppingsSystem,
  // formantToppingsChannel: null,
};
