import { Device } from '@formant/data-sdk';
import { sendCommandToFormant } from '../formant';
import {
  AnswerNotificationParameters,
  FormantGeneralCommands,
} from '../interfaces/system';

export const answerNotification = async (
  device: Device,
  parameters: AnswerNotificationParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantGeneralCommands.ANSWER_NOTIFICATION,
    parameters,
  );
};
