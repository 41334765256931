import { StoreApi, UseBoundStore } from 'zustand';

import { WithSelectors } from './types';

export function createSelectors<S extends UseBoundStore<StoreApi<object>>>(
  _store: S,
): WithSelectors<S> {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};

  const keys = Object.keys(store.getState());

  keys.forEach((k) => {
    (store.use as Record<string, unknown>)[k] = () =>
      store((s) => s[k as keyof typeof s]);
  });

  return store;
}
