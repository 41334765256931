export enum SystemStatus {
  CLEANING = 'Nettoyage',
  ERROR = 'Erreur',
  IDLE = 'Inactif',
  INITIALIZING = 'Initialisation',
  REFILLING = 'Rechargement',
  SERVICE = 'Service',
  SHUTDOWN = 'Arrêté',
}

export enum RobotStatus {
  STARTED = 0,
  INITIALIZING = 1,
  READY = 2,
  BUSY = 3,
  PAUSED = 4,
  STOPPED = 5,
  CLEANING = 6,
  ERROR = 7,
}

export enum SauceRobotStatus {
  IDLE = 'OFF',
  INITIALIZING = 'INITIALIZING',
  REFILL = 'REFILL',
  SERVICE = 'SERVICE',
  CLEANING = 'CLEANING',
  ERROR = 'ERROR',
}

// TODO: Verify statuses with the embedded team

export const getSystemStatus = (status: RobotStatus) => {
  switch (status) {
    case RobotStatus.STARTED:
      return SystemStatus.IDLE;
    case RobotStatus.INITIALIZING:
      return SystemStatus.INITIALIZING;
    case RobotStatus.READY:
      return SystemStatus.SERVICE;
    case RobotStatus.CLEANING:
      return SystemStatus.CLEANING;
    case RobotStatus.ERROR:
      return SystemStatus.ERROR;
    default:
      return SystemStatus.SHUTDOWN;
  }
};

export const getSauceSystemStatus = (status: SauceRobotStatus) => {
  switch (status) {
    case SauceRobotStatus.IDLE:
      return SystemStatus.IDLE;
    case SauceRobotStatus.INITIALIZING:
      return SystemStatus.INITIALIZING;
    case SauceRobotStatus.SERVICE:
      return SystemStatus.SERVICE;
    case SauceRobotStatus.CLEANING:
      return SystemStatus.CLEANING;
    case SauceRobotStatus.ERROR:
      return SystemStatus.ERROR;
    case SauceRobotStatus.REFILL:
      return SystemStatus.REFILLING;
    default:
      return SystemStatus.SHUTDOWN;
  }
};

export enum FormantGeneralCommands {
  ANSWER_NOTIFICATION = 'notification_response',
}

export type AnswerNotificationParameters = {
  supabaseNotificationId: number;
  notificationId: number;
  notificationResponse: string;
  timestamp: string;
};
