import { ICurrentValues } from '../../interfaces/formant';
import {
  ISauceContainer,
  ISauceContainerTemperature,
  ISauceSystem,
} from '../../interfaces/sauce';
import { IDispatch } from '../reducers';

export const dispatchSauceSystem = (sauceSystem: ISauceSystem): IDispatch => ({
  type: 'setSauceSystem',
  payload: sauceSystem,
});

export const dispatchSauceSystemStatus = (
  sauceSystemStatus: string,
): IDispatch => ({
  type: 'setSauceSystemStatus',
  payload: sauceSystemStatus,
});

export const dispatchSauceContainers = (
  sauceContainers: ISauceContainer[],
): IDispatch => ({
  type: 'setSauceContainers',
  payload: sauceContainers,
});

export const dispatchUpdateSauceContainer = (
  sauceContainer: ISauceContainer,
): IDispatch => ({
  type: 'updateSauceContainer',
  payload: sauceContainer,
});

export const dispatchContainersTemprature = (
  containersTemperatureUpdate: ISauceContainerTemperature[],
): IDispatch => ({
  type: 'setContainersTemperature',
  payload: containersTemperatureUpdate,
});

export const dispatchSauceContainersSmallPortions = (
  portions: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceContainersSmallPortions',
  payload: portions,
});

export const dispatchSauceContainersBigPortions = (
  portions: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceContainersBigPortions',
  payload: portions,
});

export const dispatchSauceContainersTemperature = (
  temperatures: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceContainersTemperature',
  payload: temperatures,
});

export const dispatchSauceContainersStock = (
  stocks: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceContainersStock',
  payload: stocks,
});

export const dispatchSauceContainersState = (
  containersState: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceContainersState',
  payload: containersState,
});

export const dispatchSauceContainersHeaterState = (
  containersHeaterState: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceContainersHeaterState',
  payload: containersHeaterState,
});

export const dispatchSauceCleaningProgress = (
  cleaningProgress: ICurrentValues[],
): IDispatch => ({
  type: 'setSauceCleaningProgress',
  payload: cleaningProgress,
});
