import dayjs from 'dayjs';
import { FormantNotification } from '../interfaces/formantNotification';
import { supabase } from './supabase';

type GetNotificationsReturnTypes = {
  lastNotification: FormantNotification;
  notifications: FormantNotification[];
};

const getCurrentServiceNotifications = (
  notifications: FormantNotification[],
) => {
  const index = notifications.findIndex((notification) => {
    return notification.robotNotificationId === 1;
  });
  const filteredNotifications = notifications.slice(0, index + 1);
  return filteredNotifications;
};

export const getNotifications = async (
  restaurantLocation: string,
): Promise<GetNotificationsReturnTypes | null> => {
  const { data, error } = await supabase
    .from('Notification')
    .select(
      '*, requiredActions:RequiredAction(description, choices:RequiredActionChoice(name, command))',
    )
    .gt('timestamp', dayjs().hour(6).valueOf())
    .eq('restaurantLocation', restaurantLocation);
  if (error) {
    console.error('Error fetching notifications', error);
    throw new Error('Error fetching notifications');
  }
  if (data) {
    const notifications = data.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : -1;
    });
    const currentServiceNotifications = getCurrentServiceNotifications([
      ...notifications,
    ]);
    const lastNotification = notifications[0];
    return { lastNotification, notifications: currentServiceNotifications };
  }
  return null;
};

export const addNotifications = async (
  newNotifications: FormantNotification[],
  notifications: FormantNotification[],
  setNotifications: (notifications: FormantNotification[]) => void,
  setLastNotification: (notification: FormantNotification) => void,
  restaurantLocation: string,
) => {
  const toAddNotifications: FormantNotification[] = [];
  await Promise.all(
    newNotifications.map(async (newNotification) => {
      const { id, name, description, status, robot, requiredActions } =
        newNotification;
      const notification = {
        name,
        description,
        status,
        type: 'INFO',
        robot: robot.toUpperCase(),
        robotNotificationId: id,
        timestamp: newNotification.timestamp,
        restaurantLocation,
      };
      const { error, data } = await supabase
        .from('Notification')
        .insert(notification)
        .select('*');
      if (error) {
        console.error('Error adding notification', error);
        throw new Error('Error adding notification');
      }
      if (data) {
        toAddNotifications.push({
          ...notification,
          id: data[0].id,
          requiredActions: requiredActions.map((requiredAction) => {
            return {
              notificationId: data[0].id,
              description: requiredAction.description,
              choices: requiredAction.choices,
            };
          }),
          restaurantLocation,
        } as FormantNotification);

        requiredActions.map(async (requiredAction) => {
          const newRequiredAction = {
            notificationId: data[0].id,
            description: requiredAction.description,
          };
          const { error: requiredActionError, data: requiredActionData } =
            await supabase
              .from('RequiredAction')
              .insert(newRequiredAction)
              .select('*');
          if (requiredActionError) {
            console.error('Error adding required action', requiredActionError);
            throw new Error('Error adding required action');
          }
          if (requiredActionData && requiredAction.choices) {
            requiredAction.choices.map(async (choice) => {
              const newRequiredActionChoice = {
                requiredActionId: requiredActionData[0].id,
                name: choice.name,
                command: choice.command,
              };
              const {
                error: requiredActionChoiceError,
                data: requiredActionChoiceData,
              } = await supabase
                .from('RequiredActionChoice')
                .insert(newRequiredActionChoice)
                .select('*');
              if (requiredActionChoiceError) {
                console.error(
                  'Error adding required action choice',
                  requiredActionChoiceError,
                );
                throw new Error('Error adding required action choice');
              }
              if (requiredActionChoiceData) {
                console.log('Required action choice added');
              }
            });
          }
        });
      }
    }),
  );
  let updatedNotifications = notifications;
  toAddNotifications.forEach((notification) => {
    const found = updatedNotifications.find((n) => {
      return n.id === notification.id;
    });
    if (!found) {
      updatedNotifications.push(notification);
    }
  });

  // TODO: remove filter out Maxon error detected notifications temporarily
  updatedNotifications = updatedNotifications
    .filter((notification) => {
      return (
        notification.name !== 'Maxon error detected' &&
        notification.name !== 'Maxon critical error detected'
      );
    })
    .sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : -1;
    });
  setLastNotification(updatedNotifications[0]);
  setNotifications(updatedNotifications);
};
