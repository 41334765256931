import { Button, ButtonGroup } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';

import { RequiredActionChoice } from '../../interfaces/formantNotification';
import { useStore } from '../../store';
import { answerNotification } from '../../utils/general';

type NotificationActionsProps = {
  choices: RequiredActionChoice[];
  notificationId: number;
  robotNotificationId: number;
};

export function NotificationActions({
  choices,
  notificationId,
  robotNotificationId,
}: NotificationActionsProps) {
  const [actionChoices, setActionChoices] = useImmer<RequiredActionChoice[]>(
    [],
  );
  const { store } = useStore();

  const { formantDevice } = store;

  const sendNotificationAnswer = (choice: string) => {
    if (!formantDevice || !notificationId) {
      return;
    }
    try {
      answerNotification(formantDevice, {
        supabaseNotificationId: notificationId || 0,
        notificationId: robotNotificationId || 0,
        notificationResponse: choice,
        timestamp: new Date().toISOString(),
      });
      // TODO: add a slack notification probably at a lower level to also get the response
    } catch (error) {
      console.error('Error sending notification answer  ', error);
      console.error(error);
    }
  };

  const sortAlphabetically = (
    a: RequiredActionChoice,
    b: RequiredActionChoice,
  ) => {
    if (!a.name || !b.name) {
      return 0;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return -1;
  };

  useEffect(() => {
    const newChoices = [...choices];
    if (newChoices) setActionChoices(newChoices.sort(sortAlphabetically));
  }, [choices]);

  return (
    <ButtonGroup spacing="5">
      {actionChoices.map((choice, i) => (
        <Button
          onClick={() => sendNotificationAnswer(choice.command)}
          // eslint-disable-next-line react/no-array-index-key
          key={`action-${i}`}>
          {choice.name}
        </Button>
      ))}
    </ButtonGroup>
  );
}
