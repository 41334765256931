import { useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  Card,
  Button,
  Stack,
  Box,
  Text,
  // useDisclosure,
} from '@chakra-ui/react';
import { IoWater } from 'react-icons/io5';
import { BsBoxFill } from 'react-icons/bs';

import { useStore } from '../../store';
import {
  getMinutesSinceCleaningStarted,
  startSauceCleaning,
} from '../../utils';
import { SwitchStack } from '../SwitchStack';
import { SauceCleaningProgress } from './SauceCleaningProgress';
import {
  CleaningStep,
  SauceCleaningDurationType,
} from '../../interfaces/sauce';
import { SystemStatus } from '../../interfaces/system';

type CurrentCleaning = {
  minutesSinceCleaningStarted: number;
  currentStep: CleaningStep;
};

export function SauceCleaning() {
  const { store } = useStore();
  const { formantDevice, sauceSystem } = store;
  const { cleaningProgress, status } = sauceSystem || {};
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [sauceContainersWithNewState, setSauceContainersWithNewState] =
    useState<boolean[]>([false, false, false, false]);
  const [currentCleaning, setCurrentCleaning] =
    useState<CurrentCleaning | null>(null);

  const getLatestCleaningStep = (steps: CleaningStep[]) => {
    return steps.reduce(
      (latest: CleaningStep | null, current: CleaningStep) => {
        const currentDate = new Date(current.startedAt);

        if (!latest || currentDate > new Date(latest.startedAt)) {
          return { ...current, date: currentDate };
        }
        return latest;
      },
      null,
    );
  };

  const getOldestCleaningStep = (steps: CleaningStep[]) => {
    return steps.reduce(
      (oldest: CleaningStep | null, current: CleaningStep) => {
        const currentDate = new Date(current.startedAt);

        if (!oldest || currentDate < new Date(oldest.startedAt)) {
          return { ...current, date: currentDate };
        }
        return oldest;
      },
      null,
    );
  };

  useEffect(() => {
    if (status === SystemStatus.CLEANING && cleaningProgress) {
      const oldestStep = getOldestCleaningStep(cleaningProgress.steps);
      const latestStep = getLatestCleaningStep(cleaningProgress.steps);
      if (!oldestStep || !latestStep) return;
      const minutesSinceCleaningStarted = getMinutesSinceCleaningStarted(
        cleaningProgress.duration,
        oldestStep.startedAt,
      );
      if (
        minutesSinceCleaningStarted &&
        minutesSinceCleaningStarted < cleaningProgress.duration
      ) {
        setCurrentCleaning({
          minutesSinceCleaningStarted,
          currentStep: latestStep,
        });
      } else {
        setCurrentCleaning(null);
      }
    }
    if (status !== SystemStatus.CLEANING) {
      setCurrentCleaning(null);
    }
  }, [status, cleaningProgress]);

  const handleSauceCleaningButtonClick = (
    durationType?: SauceCleaningDurationType,
  ) => {
    if (!formantDevice) return;
    let deepClean = false;

    if (durationType === SauceCleaningDurationType.LONG) {
      deepClean = true;
    }
    startSauceCleaning(formantDevice, {
      deep_clean: deepClean,
      containers_to_clean: sauceContainersWithNewState,
    });
  };

  const handleSwitchChange = (index: number) => {
    const newSauceContainers = sauceContainersWithNewState.map((value, i) => {
      if (i === index) {
        return !value;
      }
      return value;
    });
    setSauceContainersWithNewState(newSauceContainers);
  };

  return (
    <Card bg="white" p={4} mb={4}>
      <Heading as="h3" size="lg" className="component-heading-small" mb={2}>
        Sauces
      </Heading>

      <Flex justifyContent="center">
        <SwitchStack
          stack={sauceContainersWithNewState}
          handleSwitchChange={handleSwitchChange}
        />
      </Flex>
      {currentCleaning && (
        <Box>
          <Text>{currentCleaning.currentStep.name}</Text>
          <SauceCleaningProgress
            durationInMinutes={cleaningProgress?.duration || 0}
            minutesSinceCleaningStarted={
              currentCleaning?.minutesSinceCleaningStarted
            }
          />
        </Box>
      )}

      <Stack mt={4} direction="column" spacing={5}>
        <Button
          fontSize="2xl"
          size="lg"
          height="14"
          colorScheme="blue"
          loadingText="Nettoyage rapide en cours..."
          rightIcon={<IoWater />}
          onClick={() =>
            handleSauceCleaningButtonClick(SauceCleaningDurationType.SHORT)
          }
          isLoading={
            cleaningProgress?.type === SauceCleaningDurationType.SHORT
          }>
          Nettoyage rapide
        </Button>
        <Button
          fontSize="2xl"
          size="lg"
          height="14"
          colorScheme="blue"
          loadingText="Nettoyage profond en cours..."
          rightIcon={<BsBoxFill />}
          onClick={() =>
            handleSauceCleaningButtonClick(SauceCleaningDurationType.LONG)
          }
          isLoading={cleaningProgress?.type === SauceCleaningDurationType.LONG}>
          Nettoyage profond
        </Button>
      </Stack>
    </Card>
  );
}
