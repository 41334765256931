import dayjs from 'dayjs';
import {
  Flex,
  Card,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import { startFullCleaning } from '../../utils/pasta';
import { useStore } from '../../store';
import { SystemStatus } from '../../interfaces/system';

export function PastaCleaning() {
  const {
    store: { formantDevice, pastaSystem },
  } = useStore();

  const { status, cleaningStatus } = pastaSystem || {};

  const isToday = dayjs(cleaningStatus?.start).isSame(new Date(), 'day');
  if (!formantDevice) return null;

  const cleaningProgress = () => {
    if (
      isToday &&
      cleaningStatus &&
      status &&
      status === SystemStatus.CLEANING
    ) {
      const minuteSinceStart = dayjs().diff(
        dayjs(cleaningStatus.start),
        'minute',
      );
      const minutesInTotal = dayjs(cleaningStatus.predictedEnd).diff(
        dayjs(cleaningStatus.start),
        'minute',
      );
      const progress = (minuteSinceStart / minutesInTotal) * 100;
      if (progress > 100) {
        return null;
      }
      return (
        <Flex margin={4} justifyContent="center">
          <Flex direction="column" alignItems="center">
            <CircularProgress value={progress} color="green.400" size="120px">
              <CircularProgressLabel>
                {progress.toFixed(0)}%
              </CircularProgressLabel>
            </CircularProgress>
            <Text fontSize="m" color="black" textAlign="center" mt={2}>
              {cleaningStatus.step}
            </Text>
            <Text fontSize="m" color="black" textAlign="center" mt={2}>
              Fin prévu à {dayjs(cleaningStatus.predictedEnd)?.format('HH:mm')}
            </Text>
          </Flex>
        </Flex>
      );
    }
    return null;
  };

  return (
    <Card bg="white" p={4} mb={4}>
      <Heading as="h3" size="lg" className="component-heading-small" mb={2}>
        Pasta
      </Heading>
      {cleaningProgress()}
      <Button
        fontSize="2xl"
        size="lg"
        height="14"
        colorScheme="blue"
        isDisabled={
          status === SystemStatus.CLEANING &&
          dayjs(cleaningStatus?.predictedEnd).isAfter(dayjs())
        }
        onClick={() => startFullCleaning(formantDevice)}>
        Lancer le nettoyage
      </Button>
      <Text fontSize="m" color="red" textAlign="center" mt={10}>
        Attention à bien valider la notification de fin de pré-nettoyage manuel
        avant pour que ça passe automatiquement au nettoyage automatique. Vous
        pouvez le faire en cliquant sur la cloche en haut à droite de
        l&rsquo;écran et en repondant à la notification
      </Text>
      <Text fontSize="m" color="red" textAlign="center">
        &ldquo;🍝 Pasta - Manual cleaning &ldquo;
      </Text>
    </Card>
  );
}
