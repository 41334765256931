import { TimeIcon } from '@chakra-ui/icons';
import { Box, Card, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  FormantNotification,
  FormantNotificationStatus,
  FormantNotificationType,
  FormantRobot,
} from '../../interfaces/formantNotification';
import { NotificationActions } from './NotificationActions';

type NotificationBodyProps = {
  notification: FormantNotification;
};

export function NotificationBody({ notification }: NotificationBodyProps) {
  const getSystemName = () => {
    if (!notification) {
      return '';
    }
    switch (notification?.robot.toUpperCase()) {
      case FormantRobot.COOKER:
        return '🍝 Pasta';
      case FormantRobot.SAUCE:
        return '🥫 Sauce';
      case FormantRobot.TOPPINGS:
        return '🧀 Toppings';
      default:
        return '';
    }
  };

  const getBackgroundColor = () => {
    switch (notification?.status.toUpperCase()) {
      case FormantNotificationStatus.SENT:
        return 'yellow.500';
      case FormantNotificationStatus.RESOLVED:
        return 'red.100';
      default:
        return 'white';
    }
  };

  const getExplanation = () => {
    switch (notification?.type.toUpperCase()) {
      case FormantNotificationType.INFO:
        return null;
      case FormantNotificationType.ACTION_REQUIRED:
        return (
          <Text color="red" marginBottom="4" marginTop="6" fontWeight={800}>
            Le robot a besoin de votre réponse pour pouvoir continuer
          </Text>
        );
      case FormantNotificationType.SOLVABLE_ERROR:
        return (
          <Text color="red" marginBottom="4" marginTop="6" fontWeight={800}>
            Le robot a besoin de votre aide pour résoudre ce(s) problème(s):
          </Text>
        );
      case FormantNotificationType.CRITICAL_ERROR:
        return (
          <Text color="red" marginBottom="4" marginTop="6" fontWeight={800}>
            Le robot est en panne, faites appel à un technicien
          </Text>
        );
      default:
        return null;
    }
  };

  return (
    <Box style={{ minHeight: 400, backgroundColor: getBackgroundColor() }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h4" size="md" marginBottom={2} marginTop={5}>
          {`${getSystemName()} - ${notification?.name}`}
        </Heading>
        <Flex alignItems="center" gap={2}>
          <Icon as={TimeIcon} boxSize={4} />
          <Text>{dayjs(notification.timestamp).format('HH:mm:ss')}</Text>
        </Flex>
      </Flex>
      <Text>{notification?.description}</Text>
      {notification?.requiredActions.length > 0 && (
        <>
          {getExplanation()}
          {notification?.requiredActions.map((action, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <Card key={j} p={5}>
              <Text>{action.description}</Text>
              <Flex justifyContent="center" marginTop="5">
                {notification.id && notification.robotNotificationId && (
                  <NotificationActions
                    choices={action.choices}
                    notificationId={notification.id}
                    robotNotificationId={notification.robotNotificationId}
                  />
                )}
              </Flex>
            </Card>
          ))}
        </>
      )}
    </Box>
  );
}
