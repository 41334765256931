import { Box, Card, Grid, Heading } from '@chakra-ui/react';
import TemperatureReadout from '../../TemperatureReadout';
import ToppingsStatusTracker from './ToppingStatusTracker';
import { useStore } from '../../../store';

export function ToppingsSystem() {
  const { store } = useStore();
  const {
    toppingsSystem: { coldToppingsContainer, hotToppingsContainer },
  } = store;
  return (
    <Card p={4}>
      <Grid templateColumns="1fr 1fr" gap={4}>
        <Box>
          <Grid templateColumns="1fr 1fr" gap={4}>
            <Heading
              as="h3"
              size="md"
              className="component-heading-small"
              mb={2}>
              {hotToppingsContainer?.type}
            </Heading>
            <TemperatureReadout
              temperature={hotToppingsContainer?.temperature || 0}
              type="hot"
            />
          </Grid>
          <Box padding={3}>
            <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr" gap={4}>
              {hotToppingsContainer?.toppings.map((topping) => (
                <ToppingsStatusTracker key={topping.id} topping={topping} />
              ))}
            </Grid>
          </Box>
        </Box>

        <Box>
          <Grid templateColumns="1fr 1fr" gap={4}>
            <Heading
              as="h3"
              size="md"
              className="component-heading-small"
              mb={2}>
              {coldToppingsContainer?.type}
            </Heading>
            <TemperatureReadout
              temperature={coldToppingsContainer?.temperature || 0}
              type="cold"
            />
          </Grid>
          <Box padding={3}>
            <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr" gap={4}>
              {coldToppingsContainer?.toppings.map((topping) => (
                <ToppingsStatusTracker key={topping.id} topping={topping} />
              ))}
            </Grid>
          </Box>
        </Box>
      </Grid>
      {/* Content for the third card */}
    </Card>
  );
}
