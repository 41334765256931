import { Dispatch, createContext } from 'react';
import {
  IStore,
  initialMainStore,
  initialSauceStore,
  initialToppingsStore,
  initialPastaStore,
} from './stores';
import { IDispatch } from './reducers';

const initialStore = {
  ...initialMainStore,
  ...initialSauceStore,
  ...initialToppingsStore,
  ...initialPastaStore,
};

export const StoreStateContext = createContext<IStore>(initialStore);
export const StoreDispatchContext = createContext<Dispatch<IDispatch>>(
  () => null,
);
