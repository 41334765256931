import { ITopping, ToppingState } from '../../../interfaces/topping';

function ToppingsStatusTracker({ topping }: { topping: ITopping | undefined }) {
  let borderColor = '#ccc';
  let textColor = '#ccc';

  if (topping?.state === ToppingState.ON) {
    borderColor = 'green';
    textColor = 'green';
  } else if (topping?.state === ToppingState.OFF) {
    borderColor = 'gray';
    textColor = 'gray';
  } else if (topping?.state === ToppingState.EMPTY) {
    borderColor = 'orange'; // Use orange instead of yellow
    textColor = 'orange'; // Use orange instead of yellow
  } else if (topping?.state === ToppingState.ERROR) {
    borderColor = 'red';
    textColor = 'red';
  } else {
    borderColor = 'pink';
    textColor = 'pink';
  }

  const squareStyle = {
    width: '50px',
    height: '50px',
    backgroundColor: 'transparent',
    border: `2px solid ${borderColor}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    color: textColor,
  };

  const textStyle = {
    textAlign: 'center' as const,
    fontWeight: 'bold',
  };

  const labelStyle = {
    textAlign: 'center' as const,
    fontWeight: 'bold',
    width: '100%',
    fontSize: '12px',
    marginTop: '5px',
  };

  const containerStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  };

  const displayState = () => {
    if (!topping || !topping.state) return 'N/A';
    return topping?.state.substring(0, 3).toUpperCase();
  };

  return (
    <div style={containerStyle}>
      <div style={squareStyle}>
        <span style={textStyle}>{displayState()}</span>
      </div>
      <div style={labelStyle}>{topping?.name}</div>
    </div>
  );
}

export default ToppingsStatusTracker;
