import { SystemStatus } from './system';

export interface ISauceContainer {
  id: number;
  name: string;
  temperature: number | null;
  enabled: boolean;
  mass?: number;
  isUpdating?: boolean;
  smallPortionSize?: number;
  bigPortionSize?: number;
  sauceMargin?: number;
  index?: number;
  isHeating?: boolean;
}

export enum SauceCleaningDurationType {
  SHORT = 'short',
  LONG = 'long',
}

export interface ISauceContainerTemperature {
  name: string;
  temperature: number | null;
}

export interface CleaningStep {
  name: string;
  startedAt: string;
  endedAt: string;
}

export interface CleaningProgress {
  type: SauceCleaningDurationType;
  duration: number;
  steps: CleaningStep[];
}

export interface ISauceSystem {
  sauceContainers: ISauceContainer[];
  status: SystemStatus;
  cleaningProgress: CleaningProgress | null;
}

export enum SauceContainerStatus {
  OFF = 0,
  ON = 1,
}

export enum SauceRefillStatus {
  OFF = 0,
  ON = 1,
}

export enum FormantSauceCommands {
  UPDATE_CONTAINERS_STATUS = 'S7.containers_status_update',
  UPDATE_HEATERS_STATUS = 'S7.heaters_status_update',
  START_CLEANING = 'S7.cleaning_start',
  REBOOT = 'S7.reboot',
  UPDATE_REFILL = 'S7.refill_update',
  // TODO: Cleaning status is never used
  CLEANING_STATUS = 'S7.containers_cleaning_update',
  UPDATE_SAUCE_LEVELS = 'S7.sauce_levels_update',
}

export interface UpdateContainerStatusParameters {
  containers_status: {
    index: number;
    status: SauceContainerStatus;
    require_heating: boolean;
  }[];
}

export type StartSaucesCleaningParameters = {
  deep_clean: boolean;
  containers_to_clean: boolean[];
};

export type UpdateSaucesRefillParameters = {
  refill_status: boolean;
};

export type RebootSauceSystemParameters = Record<string, never>;

export type UpdateSauceLevelsParameters = {
  sauce_levels: { index: number; level: number }[];
};
