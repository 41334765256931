import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useState, useCallback } from 'react';
import { IoMdWarning } from 'react-icons/io';
import { useStore } from '../../store';

function ResetButton({
  name,
  sendResetCommand,
}: {
  name: string;
  sendResetCommand: () => Promise<void>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    store: { formantDevice },
  } = useStore();

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleResetClick = useCallback(() => {
    if (!formantDevice) return;
    sendResetCommand();
    setIsOpen(false);
  }, [formantDevice]);

  return (
    <>
      {/* JSX markup and component logic */}
      <Button
        fontSize="2xl"
        size="lg"
        colorScheme="red"
        onClick={() => setIsOpen(true)}>
        Réinitialiser
      </Button>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réinitialiser le système de {name} ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Une fois validée, la réinitialisation peu prendre plusieurs
              minutes.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleCloseModal}>
              Annuler
            </Button>
            <Button
              rightIcon={<IoMdWarning />}
              colorScheme="red"
              onClick={handleResetClick}>
              Réinitialiser
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ResetButton;
