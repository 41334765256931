import { Box, Stack, Switch } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

type SwitchStackProps = {
  stack: boolean[];
  handleSwitchChange: (index: number) => void;
};

export function SwitchStack({ stack, handleSwitchChange }: SwitchStackProps) {
  return (
    <Stack direction="row" spacing={12} align="center" pb="5">
      {stack.map((value, index) => (
        <Box flex="1" key={uuidv4()}>
          <Stack direction="column" spacing={2} align="center">
            <Box fontSize="xl">{index + 1}</Box>
            <Switch
              size="lg"
              isChecked={value}
              onChange={() => handleSwitchChange(index)}
            />
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}
