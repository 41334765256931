import { Badge } from '@chakra-ui/react';

interface StatusBadgeProps {
  status: string | undefined;
}

function StatusBadge({ status }: StatusBadgeProps) {
  let colorScheme;
  switch (status) {
    case 'PRÊT':
      colorScheme = 'green';
      break;
    case 'DEMARRAGE':
      colorScheme = 'blue';
      break;
    case 'HORS LIGNE':
      colorScheme = 'gray';
      break;
    case 'ERREUR':
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }

  if (!status) return null;

  return (
    <Badge
      variant="outline"
      colorScheme={colorScheme}
      textAlign="center"
      fontSize="4xl"
      mt="5">
      {status}
    </Badge>
  );
}

export default StatusBadge;
