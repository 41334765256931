import { Device } from '@formant/data-sdk';
import { sendCommandToFormant } from '../formant';
import {
  FormantSauceCommands,
  UpdateSaucesRefillParameters,
  StartSaucesCleaningParameters,
  UpdateContainerStatusParameters,
  UpdateSauceLevelsParameters,
} from '../interfaces/sauce';

export const updateSauceContainerStatus = async (
  device: Device,
  parameters: UpdateContainerStatusParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantSauceCommands.UPDATE_CONTAINERS_STATUS,
    parameters,
  );
};

export const startSauceCleaning = async (
  device: Device,
  parameters: StartSaucesCleaningParameters,
) => {
  try {
    await sendCommandToFormant(
      device,
      FormantSauceCommands.START_CLEANING,
      parameters,
    );
  } catch (error) {
    console.log('startSauceCleaning error', error);
  }
};

export const rebootSauceSystem = async (device: Device) => {
  await sendCommandToFormant(device, FormantSauceCommands.REBOOT);
};

export const updateSauceRefill = async (
  device: Device,
  parameters: UpdateSaucesRefillParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantSauceCommands.UPDATE_REFILL,
    parameters,
  );
};

export const updateSauceLevels = async (
  device: Device,
  parameters: UpdateSauceLevelsParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantSauceCommands.UPDATE_SAUCE_LEVELS,
    parameters,
  );
};

export const getMinutesSinceCleaningStarted = (
  durationInMinutes: number,
  cleaningStart: string,
) => {
  try {
    if (cleaningStart) {
      const startDate = new Date(cleaningStart);
      const currentDate = new Date();
      const diff = currentDate.getTime() - startDate.getTime();
      if (diff === 0) {
        return durationInMinutes;
      }
      const diffInMinutes = Math.floor(diff / 1000 / 60);
      if (diffInMinutes >= durationInMinutes) {
        return null;
      }
      return diffInMinutes;
    }
    return durationInMinutes;
  } catch (error) {
    console.log('getMinutesSinceCleaningStarted error', error);
  }
  return null;
};
