/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDispatch } from '../reducers';

export const dispatchPastaSystemStatus = (
  pastaSystemStatus: any,
): IDispatch => ({
  type: 'setPastaSystemStatus',
  payload: pastaSystemStatus,
});

export const dispatchPastaCookerInfo = (pastaCookerInfo: any): IDispatch => ({
  type: 'setPastaCookerInfo',
  payload: pastaCookerInfo,
});

export const dispatchPastaHeatingTowerStatus = (
  pastaHeatingTowerStatus: any,
): IDispatch => ({
  type: 'setPastaHeatingTowerStatus',
  payload: pastaHeatingTowerStatus,
});

export const dispatchPastaSamplingPortions = (
  pastaSamplingPortions: any[],
): IDispatch => ({
  type: 'setPastaSamplingPortions',
  payload: pastaSamplingPortions,
});

export const dispatchCleaningStatus = (cleaningStatus: any): IDispatch => ({
  type: 'setCleaningStatus',
  payload: cleaningStatus,
});

export const dispatchPastaCleaningStep = (step: string): IDispatch => ({
  type: 'setPastaCleaningStep',
  payload: step,
});

export const dispatchPastaCleaningStart = (start: string): IDispatch => ({
  type: 'setPastaCleaningStart',
  payload: start,
});

export const dispatchPastaCleaningPredictedEnd = (
  predictedEnd: string,
): IDispatch => ({
  type: 'setPastaCleaningPredictedEnd',
  payload: predictedEnd,
});
