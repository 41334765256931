import { StreamType } from '@formant/data-sdk';
import { IDispatch } from '../store/reducers';
import {
  dispatchColdToppingsContainersStatuses,
  dispatchHotToppingsContainersStatuses,
  dispatchPastaCookerInfo,
  dispatchSauceContainersHeaterState,
  dispatchSauceContainersState,
  dispatchSauceContainersStock,
  dispatchSauceContainersTemperature,
  dispatchSauceSystemStatus,
  dispatchToppingsContainersTemperature,
  dispatchPastaCleaningStep,
  dispatchPastaCleaningStart,
  dispatchPastaCleaningPredictedEnd,
} from '../store';
import { ICurrentValues } from '../interfaces/formant';
// import { FormantNotification } from '../interfaces/formantNotification';

enum SauceStreamName {
  AIR_PRESSURE = 'air_pressure',
  CONTAINERS_ACTIVATION_STATUS = 'containers_activation_status',
  CONTAINERS_HEATERS_STATUS = 'containers_heaters_status',
  CONTAINERS_STOCK = 'containers_stock',
  CONTAINERS_TEMPERATURE = 'containers_temperature',
  INJECTIONS_RECAP = 'injections_recap',
  SYSTEM_STATUS = 'system_status',
}

enum ToppingsStreamName {
  COLD_TOPPINGS_CONTAINERS_STATUS = 'container_status_cold',
  HOT_TOPPINGS_CONTAINERS_STATUS = 'container_status_hot',
  MASSES_FINAL = 'masses_final',
  MASSES_RESAMPLING = 'masses_resampling',
  MASSES_SAMPLING = 'masses_sampling',
  SAMPLING_DURATION = 'sampling_duration',
  TOPPINGS_TEMPERATURES = 'toppings_temperatures',
}

enum PastaStreamName {
  COOKER_STATUS = 'cooker_status',
  GENERAL_STATUS = 'general_status',
  HEATING_TOWER_STATUS = 'heating_tower_status',
  SAMPLING_PORTIONS = 'sampling_portions',
  CLEANING_STEP = 'cleaning_step',
  CLEANING_START = 'cleaning_start',
  CLEANING_PREDICTED_END = 'cleaning_end_prediction',
}

enum GeneralStreamName {
  NOTIFICATION = 'notification',
}

enum SystemName {
  SAUCE = 'S7',
  TOPPINGS = 'T4',
  PASTA = 'C6',
  GENERAL = 'General',
}

interface ITelemetryItem {
  id: string;
  deviceId: string;
  currentValue: ICurrentValues[] | string;
  currentValueTime: string;
  organisationId: string;
  streamName: SauceStreamName | ToppingsStreamName | PastaStreamName;
  streamType: StreamType;
  name: string;
  value: number;
  tags?: {
    restaurant: string;
  };
}

const extractStreamName = (streamName: string) => {
  const streamNameArray = streamName.split('.');
  return streamNameArray[streamNameArray.length - 1];
};

const parseSauceTelemertry = (
  telemetry: ITelemetryItem,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  dispatch: (value: IDispatch) => void,
) => {
  const { streamName, currentValue } = telemetry;
  const streamNameExtracted = extractStreamName(streamName);
  switch (streamNameExtracted) {
    case SauceStreamName.SYSTEM_STATUS:
      if (typeof currentValue === 'string') {
        dispatch(dispatchSauceSystemStatus(currentValue));
      }
      break;
    case SauceStreamName.AIR_PRESSURE:
      // dispatch(dispatchSauceContainersBigPortions(currentValue));
      break;
    case SauceStreamName.CONTAINERS_HEATERS_STATUS:
      if (typeof currentValue !== 'string') {
        dispatch(dispatchSauceContainersHeaterState(currentValue));
      }
      break;
    case SauceStreamName.CONTAINERS_STOCK:
      if (typeof currentValue !== 'string') {
        dispatch(dispatchSauceContainersStock(currentValue));
      }
      break;
    case SauceStreamName.CONTAINERS_ACTIVATION_STATUS:
      if (typeof currentValue !== 'string') {
        dispatch(dispatchSauceContainersState(currentValue));
      }
      break;
    case SauceStreamName.CONTAINERS_TEMPERATURE:
      if (typeof currentValue !== 'string') {
        dispatch(dispatchSauceContainersTemperature(currentValue));
      }
      break;
    case SauceStreamName.INJECTIONS_RECAP:
      // dispatch(dispatchSauceCleaningProgress(currentValue));
      break;
    default:
      break;
  }
};

const parsePastaTelemertry = (
  telemetry: ITelemetryItem,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  dispatch: (value: IDispatch) => void,
) => {
  const { streamName, currentValue } = telemetry;
  const streamNameExtracted = extractStreamName(streamName);
  switch (streamNameExtracted) {
    case PastaStreamName.COOKER_STATUS:
      dispatch(dispatchPastaCookerInfo(currentValue));
      break;
    case PastaStreamName.GENERAL_STATUS:
      // console.log(streamNameExtracted, currentValue);
      break;
    case PastaStreamName.HEATING_TOWER_STATUS:
      // console.log(streamNameExtracted, currentValue);
      break;
    case PastaStreamName.SAMPLING_PORTIONS:
      // console.log(streamNameExtracted, currentValue);
      break;
    case PastaStreamName.CLEANING_STEP:
      if (typeof currentValue === 'string') {
        dispatch(dispatchPastaCleaningStep(currentValue));
      }
      break;
    case PastaStreamName.CLEANING_START:
      if (typeof currentValue === 'string') {
        dispatch(dispatchPastaCleaningStart(currentValue));
      }
      break;
    case PastaStreamName.CLEANING_PREDICTED_END:
      if (typeof currentValue === 'string') {
        dispatch(dispatchPastaCleaningPredictedEnd(currentValue));
      }
      break;
    default:
      // console.log(streamNameExtracted, currentValue);
      break;
  }
};

const parseToppingsTelemertry = (
  telemetry: ITelemetryItem,
  dispatch: (value: IDispatch) => void,
) => {
  const { streamName, currentValue } = telemetry;
  const streamNameExtracted = extractStreamName(streamName);
  if (typeof currentValue !== 'string') {
    switch (streamNameExtracted) {
      case ToppingsStreamName.TOPPINGS_TEMPERATURES:
        dispatch(dispatchToppingsContainersTemperature(currentValue));
        break;
      case ToppingsStreamName.HOT_TOPPINGS_CONTAINERS_STATUS:
        dispatch(dispatchHotToppingsContainersStatuses(currentValue));
        break;
      case ToppingsStreamName.COLD_TOPPINGS_CONTAINERS_STATUS:
        dispatch(dispatchColdToppingsContainersStatuses(currentValue));
        break;
      case ToppingsStreamName.MASSES_FINAL:
        break;
      case ToppingsStreamName.MASSES_RESAMPLING:
        break;
      case ToppingsStreamName.MASSES_SAMPLING:
        break;
      case ToppingsStreamName.SAMPLING_DURATION:
        break;
      default:
        break;
    }
  }
};

export const parseGeneralTelemertry = (
  telemetry: ITelemetryItem,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  dispatch: (value: IDispatch) => void,
) => {
  const { streamName, currentValue } = telemetry;
  const streamNameExtracted = extractStreamName(streamName);
  if (typeof currentValue === 'string') {
    switch (streamNameExtracted) {
      case GeneralStreamName.NOTIFICATION:
        // console.log('GeneralStreamName.NOTIFICATION', currentValue);
        // eslint-disable-next-line no-case-declarations
        // const notification = JSON.parse(currentValue) as FormantNotification;
        break;
      default:
        break;
    }
  }
};

export const parseFormantTelemetry = (
  dispatch: (value: IDispatch) => void,
  telemetry: ITelemetryItem[],
  tag?: string,
) => {
  telemetry
    .filter((telemetryItem: ITelemetryItem) => {
      if (tag) {
        if (telemetryItem.tags) {
          return telemetryItem.tags.restaurant === tag;
        }
        return false;
      }
      return true;
    })
    .forEach((telemetryItem: ITelemetryItem) => {
      const { streamName } = telemetryItem;
      const streamInfo = streamName.split('.');
      const system = streamInfo[0];
      if (system) {
        switch (system) {
          case SystemName.SAUCE:
            parseSauceTelemertry(telemetryItem, dispatch);
            break;
          case SystemName.TOPPINGS:
            parseToppingsTelemertry(telemetryItem, dispatch);
            break;
          case SystemName.PASTA:
            parsePastaTelemertry(telemetryItem, dispatch);
            break;
          case SystemName.GENERAL:
            parseGeneralTelemertry(telemetryItem, dispatch);
            break;
          default:
            break;
        }
      }
    });
};
