import { produce } from 'immer';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { FormantNotification } from '../interfaces/formantNotification';

import { Actions, State } from './types/general';
import { createSelectors } from './utils/createSelectors';

const useStoreBase = create<State & Actions>()(
  immer((set) => ({
    notifications: [],
    lastNotification: null,
    playingSound: false,
    setNotifications: (notifications) => {
      set(
        produce((draft) => {
          notifications.forEach((notification: FormantNotification, index) => {
            const found = draft.notifications.find(
              (n: FormantNotification) => n.id === notification.id,
            );
            if (found && found.status !== notification.status) {
              draft.notifications[index] = notification;
            } else if (!found) {
              draft.notifications.push(notification);
            }
          });
        }),
      );
    },
    setLastNotification: (notification) => {
      set((state) => {
        state.lastNotification = notification;
      });
    },
    setPlayingSound: (playing) => {
      set((state) => {
        state.playingSound = playing;
      });
    },
  })),
);

export const useGeneralStore = createSelectors(useStoreBase);
