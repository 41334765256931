import { Card, Checkbox, Stack, Text } from '@chakra-ui/react';

type PastaCookerContainerProps = {
  name: string;
  temperature: number;
  enabled: boolean;
  handlePastaContainerChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};

export function PastaCookerContainer({
  name,
  temperature,
  enabled,
  handlePastaContainerChange,
}: PastaCookerContainerProps) {
  return (
    <Stack>
      <Checkbox
        key={name}
        size="lg"
        id={`${name}-checkbox`}
        isChecked={enabled}
        disabled
        onChange={(event) => handlePastaContainerChange(event, name)}>
        {name}
      </Checkbox>
      {temperature !== 0 && (
        <Card p="5">
          <Text>Température: {temperature}°C</Text>
        </Card>
      )}
    </Stack>
  );
}
