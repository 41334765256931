/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useReducer, useMemo, useCallback } from 'react';

import { StoreStateContext, StoreDispatchContext } from './context';
import {
  initialMainStore as defaultMainStore,
  initialSauceStore as defaultSauceStore,
  initialToppingsStore as defaultToppingsStore,
  initialPastaStore as defaultPastaStore,
} from './stores';
import {
  mainReducer,
  sauceReducer,
  toppingsReducer,
  pastaReducer,
  IDispatch,
} from './reducers';

type Props = {
  children: ReactNode;
};

const combineDispatch =
  (...dispatches: any[]) =>
  (action: IDispatch) =>
    dispatches.forEach((dispatch) => dispatch(action));

export function StoreProvider({ children }: Props) {
  const [mainStore, mainDispatch] = useReducer(mainReducer, defaultMainStore);
  const [sauceStore, sauceDispatch] = useReducer(
    sauceReducer,
    defaultSauceStore,
  );
  const [toppingsStore, toppingsDispatch] = useReducer(
    toppingsReducer,
    defaultToppingsStore,
  );
  const [pastaStore, pastaDispatch] = useReducer(
    pastaReducer,
    defaultPastaStore,
  );
  const combinedDispatch = useCallback(
    combineDispatch(
      mainDispatch,
      sauceDispatch,
      toppingsDispatch,
      pastaDispatch,
    ),
    [mainDispatch, sauceDispatch, toppingsDispatch, pastaDispatch],
  );
  const combinedState = useMemo(
    () => ({ ...mainStore, ...sauceStore, ...toppingsStore, ...pastaStore }),
    [mainStore, sauceStore, toppingsStore, pastaStore],
  );

  return (
    <StoreDispatchContext.Provider value={combinedDispatch}>
      <StoreStateContext.Provider value={combinedState}>
        {children}
      </StoreStateContext.Provider>
    </StoreDispatchContext.Provider>
  );
}
