import {
  Button,
  Card,
  Grid,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useStore } from '../../../store';
import { sendPastaDishesBatch } from '../../../utils/pasta';

export function BatchCooking() {
  const [cookingBatchSize, setCookingBatchSize] = useState(10);
  const {
    store: { formantDevice },
  } = useStore();

  const sendCookingBatch = () => {
    if (!formantDevice) return;
    const batch = {
      batch_size: cookingBatchSize,
    };
    sendPastaDishesBatch(formantDevice, batch);
  };
  return (
    <Card p="3">
      <Stack spacing={4}>
        <Heading as="h4" size="md" className="component-heading-small">
          Envoi instantané
        </Heading>
        <Grid templateColumns="1fr 1fr" gap={4}>
          <Text display="flex" alignItems="center">
            Nombre de plats
          </Text>

          <NumberInput
            defaultValue={10}
            min={1}
            max={19}
            size="lg"
            value={cookingBatchSize}
            onChange={(value) => setCookingBatchSize(Number(value))}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Grid>
        <Button
          fontSize="2xl"
          size="lg"
          colorScheme="blue"
          onClick={() => sendCookingBatch()}>
          Mettre en cuisson
        </Button>
      </Stack>
    </Card>
  );
}
