export interface ICurrentValues {
  [key: string]: string | number | boolean;
}

export interface IStreamData {
  currentValue: ICurrentValues[];
  streamName: string;
}

export enum RestaurantTag {
  NATION = 'nation',
  TEST = 'test',
  GENTILLY = 'gentilly',
  MADELEINE = 'madeleine',
}

export enum FormantDeviceName {
  NATION = 'S5-Nation',
  TEST = 'test_device',
  GENTILLY = 'Gentilly',
  MADELEINE = 'madeleine',
  POISSONIERE = 'poissoniere',
  BOETIE = 'boetie',
}
