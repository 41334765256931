/* eslint-disable no-lonely-if */
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
  Box,
  useDisclosure,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FormantNotification,
  FormantNotificationStatus,
  FormantNotificationType,
} from '../../interfaces/formantNotification';
// import { useStore } from '../../store';
import { NotificationNavigation } from './NotificationNavigation';
import { NotificationBody } from './NotificationBody';
import { NotificationButton } from './NotificationButton';
import { NotificationHistory } from './NotificationHistory';
import { useGeneralStore } from '../../stores/useGeneralStore';
import { useAudio } from '../../hooks/useAudio';
import audioNotification from '../../assets/notification.mp3';

dayjs.extend(isToday);

export function NotificationDisplay() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeNotifications, setActiveNotifications] = useState<
    FormantNotification[]
  >([]);
  const [index, setIndex] = useState<number>(0);
  const notifications = useGeneralStore.use.notifications();
  const { updatePLayState } = useAudio(audioNotification);

  useEffect(() => {
    const newActiveNotifications = notifications
      .filter(
        (notification) =>
          notification.status !== FormantNotificationStatus.RESOLVED,
      )
      .sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        return 0;
      });
    if (newActiveNotifications.length > 0) {
      updatePLayState(true);
    } else {
      updatePLayState(false);
    }
    setActiveNotifications(newActiveNotifications);
  }, [notifications]);

  const getTitle = () => {
    if (activeNotifications.length === 0) {
      return 'Notifications';
    }
    switch (activeNotifications[index]?.type.toUpperCase()) {
      case FormantNotificationType.ACTION_REQUIRED:
        return "🤖 Le robot demande de l'aide";
      case FormantNotificationType.CRITICAL_ERROR:
        return '❌ Erreur critique';
      case FormantNotificationType.SOLVABLE_ERROR:
        return '🤖 🧰 Le robot demande de l&rsquo;aide';
      case FormantNotificationType.INFO:
        return '⚠️ Information';
      default:
        return '';
    }
  };

  const getCurrentNotifications = () => {
    return (
      <Box>
        {activeNotifications.length > 0 ? (
          <>
            <NotificationBody notification={activeNotifications[index]} />
            <NotificationNavigation
              index={index}
              setIndex={setIndex}
              notificationNumber={activeNotifications.length}
            />
          </>
        ) : (
          <Text>Aucune nouvelle notification</Text>
        )}
      </Box>
    );
  };

  return (
    <>
      <NotificationButton
        onOpen={onOpen}
        activeNotifications={activeNotifications}
      />
      <Modal size="xl" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{getTitle()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Nouvelles</Tab>
                <Tab>Historique</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>{getCurrentNotifications()}</TabPanel>
                <TabPanel>
                  <NotificationHistory />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
