import { ICurrentValues } from '../../interfaces/formant';
import {
  getToppingName,
  ITopping,
  RobotToppingContainerType,
  ToppingContainerType,
} from '../../interfaces/topping';
import { retreiveToppingsNames } from '../../utils/localStorage';
import {
  getToppingStatusFromRobotValue,
  getToppingsTypeFromRobotIndex,
} from '../../utils/toppings';
import { IToppingsStore } from '../stores';

export type ToppingsDispatchAction =
  | 'setToppingsSystem'
  | 'updateToppingName'
  | 'updateToppingsContainersTemperature'
  | 'updateColdToppingsContainersStatuses'
  | 'updateHotToppingsContainersStatuses';
// | 'setFormantToppingsChannel'

export interface IToppingsDispatch {
  type: ToppingsDispatchAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

const HOT_TOPPING_OFFSET = 7;

export const toppingsReducer = (
  state: IToppingsStore,
  { type, payload }: IToppingsDispatch,
) => {
  switch (type) {
    case 'setToppingsSystem':
      return { ...state, toppingsSystem: payload };
    case 'updateToppingName': {
      const { toppingName, toppingIndex, toppingType } = payload;
      const updatingToppingContainersItems =
        toppingType === ToppingContainerType.COLD
          ? state.toppingsSystem?.coldToppingsContainer?.toppings
          : state.toppingsSystem?.hotToppingsContainer?.toppings;

      if (!updatingToppingContainersItems) return state;

      updatingToppingContainersItems[toppingIndex].name = toppingName;
      if (toppingType === ToppingContainerType.COLD) {
        return {
          ...state,
          toppingsSystem: {
            ...state.toppingsSystem,
            coldToppingsContainers: {
              ...state.toppingsSystem?.coldToppingsContainer,
              toppings: updatingToppingContainersItems,
            },
          },
        };
      }
      return {
        ...state,
        toppingsSystem: {
          ...state.toppingsSystem,
          hotToppingsContainers: {
            ...state.toppingsSystem?.hotToppingsContainer,
            toppings: updatingToppingContainersItems,
          },
        },
      };
    }
    case 'updateToppingsContainersTemperature': {
      const newTemperatures = payload as ICurrentValues[];
      const hotContainerTemperature = newTemperatures.find(
        (currentValue: ICurrentValues) =>
          getToppingsTypeFromRobotIndex(Number(currentValue.label)) ===
          ToppingContainerType.HOT,
      );
      const coldContainerTemperature = newTemperatures.find(
        (currentValue: ICurrentValues) =>
          getToppingsTypeFromRobotIndex(Number(currentValue.label)) ===
          ToppingContainerType.COLD,
      );

      return {
        ...state,
        toppingsSystem: {
          ...state.toppingsSystem,
          coldToppingsContainer: {
            ...state.toppingsSystem?.coldToppingsContainer,
            temperature: coldContainerTemperature?.value,
          },
          hotToppingsContainer: {
            ...state.toppingsSystem?.hotToppingsContainer,
            temperature: hotContainerTemperature?.value,
          },
        },
      };
    }
    case 'updateColdToppingsContainersStatuses': {
      const newStatuses = payload as ICurrentValues[];
      const updatedColdToppings: ITopping[] = newStatuses.map(
        (currentValue: ICurrentValues) => {
          const found =
            state.toppingsSystem?.coldToppingsContainer?.toppings?.find(
              (topping) => topping.id === Number(currentValue.label),
            );
          if (found) {
            return {
              ...found,
              state: getToppingStatusFromRobotValue(Number(currentValue.value)),
            };
          }
          return {
            id: Number(currentValue.label),
            name: getToppingName(
              Number(currentValue.label) + 1 + HOT_TOPPING_OFFSET,
            ),
            state: getToppingStatusFromRobotValue(Number(currentValue.value)),
          };
        },
      );
      const updatedColdToppingsWithNames = retreiveToppingsNames(
        RobotToppingContainerType.COLD,
        updatedColdToppings,
      );
      return {
        ...state,
        toppingsSystem: {
          ...state.toppingsSystem,
          coldToppingsContainer: {
            ...state.toppingsSystem?.coldToppingsContainer,
            toppings: updatedColdToppingsWithNames,
          },
        },
      };
    }
    case 'updateHotToppingsContainersStatuses': {
      const newStatuses = payload as ICurrentValues[];
      const updatedHotToppings: ITopping[] = newStatuses.map(
        (currentValue: ICurrentValues) => {
          const found =
            state.toppingsSystem?.hotToppingsContainer?.toppings?.find(
              (topping) => topping.id === Number(currentValue.label),
            );
          if (found) {
            return {
              ...found,
              state: getToppingStatusFromRobotValue(Number(currentValue.value)),
            };
          }
          return {
            id: Number(currentValue.label),
            name: getToppingName(Number(currentValue.label) + 1),
            state: getToppingStatusFromRobotValue(Number(currentValue.value)),
          };
        },
      );
      const updatedHotToppingsWithNames = retreiveToppingsNames(
        RobotToppingContainerType.HOT,
        updatedHotToppings,
      );
      return {
        ...state,
        toppingsSystem: {
          ...state.toppingsSystem,
          hotToppingsContainer: {
            ...state.toppingsSystem?.hotToppingsContainer,
            toppings: updatedHotToppingsWithNames,
          },
        },
      };
    }
    default:
      return state;
  }
};
