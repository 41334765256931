import { SystemStatus } from './system';

export interface ITopping {
  id: number;
  name: string;
  state: ToppingState;
}

export interface IToppingContainer {
  type: ToppingContainerType;
  temperature: number;
  toppings: ITopping[] | [];
  enabled: boolean;
}

export enum ToppingState {
  ON = 'on',
  OFF = 'off',
  EMPTY = 'empty',
  ERROR = 'error',
}

export enum ToppingContainerType {
  HOT = 'Chaud',
  COLD = 'Froid',
}

export enum RobotToppingContainerType {
  HOT = 'hot',
  COLD = 'cold',
}

export interface IToppingInformation {
  topping: ITopping;
  type: ToppingContainerType;
}

export interface IToppingSystem {
  status: SystemStatus | null;
  coldToppingsContainer: IToppingContainer | null;
  hotToppingsContainer: IToppingContainer | null;
}

export enum FormantToppingsCommands {
  UPDATE_TOPPINGS_STATUS = 'T4.update_toppings_status',
  REBOOT = 'T4.reboot',
}

export type UpdateToppingsStatusParameters = {
  cold_toppings_status: { index: number; status: boolean }[];
  hot_toppings_status: { index: number; status: boolean }[];
};

export type IRefillToppingsParameters = Record<string, never>;

export const getToppingName = (index: number): string => {
  switch (index) {
    case 1:
      return 'Guanciale';
    case 2:
      return 'Guanciale';
    case 3:
      return 'Crouton';
    case 4:
      return 'Champignons';
    case 5:
      return 'Champignons';
    case 6:
      return 'Poulet';
    case 7:
      return 'Poulet';
    case 8:
      return 'Quinoa';
    case 9:
      return 'Ognions';
    case 10:
      return 'Noix';
    case 11:
      return 'Tomates';
    case 12:
      return 'Comté';
    case 13:
      return 'Grana';
    case 14:
      return 'Parmesan';
    default:
      return 'Sans nom';
  }
};
