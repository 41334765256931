import { useEffect, useState } from 'react';
import { Card, Flex, Heading, Button, SimpleGrid } from '@chakra-ui/react';

import SauceContainer from './SauceContainer';
import { useStore } from '../../../store/useStore';
import ResetButton from '../ResetButton';
import StatusBadge from '../../StatusBadge';
import {
  ISauceContainer,
  SauceContainerStatus,
} from '../../../interfaces/sauce';
import { rebootSauceSystem, updateSauceContainerStatus } from '../../../utils';
import { SauceRefillModal } from './SauceRefillModal';

export function SauceSystem() {
  const { store } = useStore();
  const { sauceSystem, formantDevice } = store;
  const { sauceContainers } = sauceSystem || {};
  const [sauceContainersWithNewState, setSauceContainersWithNewState] =
    useState<ISauceContainer[]>([]);

  useEffect(() => {
    if (sauceContainersWithNewState.length === 0 && sauceContainers) {
      const initialCheckboxStates = sauceContainers.map(
        (container: ISauceContainer) => container,
      );
      setSauceContainersWithNewState(initialCheckboxStates);
    }
  }, [sauceContainers]);

  const handleSauceStart = () => {
    if (!formantDevice || !sauceContainers) return;
    updateSauceContainerStatus(formantDevice, {
      containers_status: sauceContainersWithNewState.map((container) => ({
        index: container.id,
        status: container.enabled
          ? SauceContainerStatus.ON
          : SauceContainerStatus.OFF,
        require_heating: (container.isHeating && container.enabled) || false,
      })),
    });
  };

  const handleRebootSauceSystem = async () => {
    if (formantDevice) {
      rebootSauceSystem(formantDevice);
    }
  };

  if (!sauceContainers) {
    return null;
  }

  return (
    <Card
      bg="white"
      p={4}
      flex="1"
      style={{
        opacity: 1,
      }}>
      <Heading as="h3" size="lg" className="component-heading-small" mb={2}>
        Sauces
      </Heading>

      <StatusBadge status={sauceSystem?.status} />

      <SimpleGrid mt="5" spacing={4} templateColumns="1fr 1fr 1fr 1fr">
        {sauceContainersWithNewState.map(
          (container: ISauceContainer, index) => (
            <SauceContainer
              key={container.id || index}
              sauceContainer={container}
              sauceContainersWithNewState={sauceContainersWithNewState}
              setSauceContainersWithNewState={setSauceContainersWithNewState}
            />
          ),
        )}
      </SimpleGrid>

      <Flex justifyContent="space-around" mt={5}>
        <ResetButton
          name="sauce"
          sendResetCommand={() => handleRebootSauceSystem()}
        />
        <Button
          fontSize="2xl"
          size="lg"
          colorScheme="blue"
          onClick={() => handleSauceStart()}>
          Démarrer
        </Button>
        <SauceRefillModal />
      </Flex>
    </Card>
  );
}
