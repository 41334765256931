import { Device } from '@formant/data-sdk';
import { sendCommandToFormant } from '../formant/commands';

import {
  FormantToppingsCommands,
  ToppingContainerType,
  ToppingState,
  UpdateToppingsStatusParameters,
} from '../interfaces/topping';

export const getToppingsTypeFromRobotIndex = (
  index: number,
): ToppingContainerType => {
  switch (index) {
    case 0:
      return ToppingContainerType.COLD;
    case 1:
      return ToppingContainerType.COLD;
    case 2:
      return ToppingContainerType.HOT;
    case 3:
      return ToppingContainerType.HOT;
    default:
      throw new Error('Unknown topping type');
  }
};

export const getToppingStatusFromRobotValue = (value: number): ToppingState => {
  switch (value) {
    case 0:
      return ToppingState.OFF;
    case 1:
      return ToppingState.ON;
    case 2:
      return ToppingState.EMPTY;
    case 3:
      return ToppingState.ERROR;
    default:
      throw new Error('Unknown topping status');
  }
};

export const updateToppingsStatus = async (
  device: Device,
  parameters: UpdateToppingsStatusParameters,
) => {
  await sendCommandToFormant(
    device,
    FormantToppingsCommands.UPDATE_TOPPINGS_STATUS,
    parameters,
  );
};

export const rebootToppingsSystem = async (device: Device) => {
  await sendCommandToFormant(device, FormantToppingsCommands.REBOOT);
};
