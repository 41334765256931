/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  Grid,
  Heading,
  Button,
  Stack,
  Flex,
  Text,
} from '@chakra-ui/react';

import StatusBadge from '../../StatusBadge';
import ResetButton from '../ResetButton';
import { useStore } from '../../../store/useStore';
import { PastaCookerContainer } from './PastaCookerContainer';
import { initilizePastaSystem, rebootPastaSystem } from '../../../utils/pasta';
// import { dispatchPastaCookerInfo } from '../../../store';

export function PastaSystem() {
  const {
    store: { formantDevice, pastaSystem },
    // dispatch,
  } = useStore();

  const { leftPastaContainer, rightPastaContainer, status } = pastaSystem || {};

  const handlePastaStartButtonClick = async () => {
    if (formantDevice) {
      // const containersState = pastaContainers.map((container) => {
      //   return container.enabled;
      // });
      initilizePastaSystem(formantDevice);
    }
  };

  const handlePastaContainerChange = () =>
    // event: React.ChangeEvent<HTMLInputElement>,
    // name: string,
    {
      // const updatedContainers = [];
      // dispatch(dispatchPastaCookerInfo(updatedContainers));
    };

  const handleRebootPastaSystem = async () => {
    if (formantDevice) {
      rebootPastaSystem(formantDevice);
    }
  };

  return (
    <Card bg="white" p={4} mb={4}>
      <Grid templateColumns="1fr 1fr" gap={4}>
        <Box>
          <Heading as="h3" size="lg" className="component-heading-small" mb={2}>
            Pasta
          </Heading>
          <Text p="3">Activation indisponible pour le moment</Text>
          <Flex alignItems="center" justifyContent="space-around">
            <PastaCookerContainer
              temperature={leftPastaContainer?.temperature || 0}
              name="Gauche"
              handlePastaContainerChange={handlePastaContainerChange}
              enabled={false}
            />
            <PastaCookerContainer
              temperature={rightPastaContainer?.temperature || 0}
              name="Droite"
              enabled
              handlePastaContainerChange={handlePastaContainerChange}
            />
          </Flex>
        </Box>
        <Box>
          <Stack spacing={4}>
            <StatusBadge status={status} />
            <Button
              fontSize="2xl"
              size="lg"
              colorScheme="blue"
              onClick={() => handlePastaStartButtonClick()}>
              Démarrer
            </Button>
            <ResetButton
              name="cuisson"
              sendResetCommand={() => handleRebootPastaSystem()}
            />
          </Stack>
        </Box>
      </Grid>
    </Card>
  );
}
