import {
  useDisclosure,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Button,
  Input,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ISauceContainer } from '../../../interfaces/sauce';
import { SystemStatus } from '../../../interfaces/system';
import { useStore } from '../../../store';
import { updateSauceRefill, updateSauceLevels } from '../../../utils/sauce';
import SauceContainer from './SauceContainer';

type SauceRefillLevel = {
  index: number;
  level: number | null;
};

export function SauceRefillModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { store } = useStore();
  const { sauceSystem, formantDevice } = store;
  const { sauceContainers, status } = sauceSystem || {};
  const [addedSauceLevels, setAddedSauceLevels] = useState<SauceRefillLevel[]>(
    [],
  );

  const initializeSauceLevels = () => {
    if (sauceContainers) {
      const newAddedSauceLevels: SauceRefillLevel[] = [];
      sauceContainers.forEach((_, index) => {
        newAddedSauceLevels.push({ index, level: null });
      });
      setAddedSauceLevels(newAddedSauceLevels);
    }
  };

  useEffect(() => {
    if (sauceContainers && addedSauceLevels.length === 0) {
      initializeSauceLevels();
    }
  }, [sauceContainers]);

  const handleSauceRefill = async () => {
    if (!formantDevice) return;
    await updateSauceLevels(formantDevice, {
      sauce_levels: addedSauceLevels.map((sauce) => ({
        index: sauce.index,
        level: sauce.level || 0,
      })),
    });
    onClose();
  };

  const stopRefill = () => {
    if (!formantDevice) return;
    if (status === SystemStatus.REFILLING) {
      updateSauceRefill(formantDevice, {
        refill_status: false,
      });
    }
  };

  const updateSauceRefillLevel = (index: number, level: number) => {
    const newAddedSauceLevels = [...addedSauceLevels];
    newAddedSauceLevels[index] = {
      index,
      level,
    };
    setAddedSauceLevels(newAddedSauceLevels);
  };

  useEffect(() => {
    if (formantDevice) {
      if (isOpen && status !== SystemStatus.REFILLING) {
        updateSauceRefill(formantDevice, {
          refill_status: true,
        });
      }
      if (!isOpen) {
        initializeSauceLevels();
      }
    }
    return () => stopRefill();
  }, [isOpen]);

  if (!sauceContainers) return null;

  return (
    <>
      <Button
        fontSize="2xl"
        size="lg"
        colorScheme="green"
        onClick={() => (!isOpen ? onOpen() : onClose())}>
        Recharger
      </Button>
      <Modal size="4xl" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Informe le robot des quantités ajoutées</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column" margin={5} justifyContent="center">
              <SimpleGrid mt="5" spacing={4} templateColumns="1fr 1fr 1fr 1fr">
                {addedSauceLevels.length > 0 &&
                  sauceContainers.map((container: ISauceContainer, index) => (
                    <Flex
                      flexDirection="column"
                      gap={5}
                      key={container.id || index}>
                      <SauceContainer sauceContainer={container} />
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        gap={2}>
                        <Text fontSize={14}>Quantité ajoutée</Text>
                        <Input
                          size="md"
                          type="number"
                          placeholder="Quantité en mL"
                          value={addedSauceLevels[index].level || ''}
                          onChange={(e) => {
                            updateSauceRefillLevel(
                              index,
                              parseInt(e.target.value, 10),
                            );
                          }}
                        />
                      </Flex>
                    </Flex>
                  ))}
              </SimpleGrid>
              <Button
                mt={5}
                colorScheme="green"
                onClick={() => {
                  handleSauceRefill();
                }}>
                Valider
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
