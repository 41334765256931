import { createClient } from '@supabase/supabase-js';

if (
  !process.env.REACT_APP_SUPABASE_API_KEY ||
  !process.env.REACT_APP_SUPABASE_PROJECT_URL
) {
  throw new Error(
    'Please provide REACT_APP_SUPABASE_PROJECT_URL and REACT_APP_SUPABASE_API_KEY',
  );
}

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_PROJECT_URL,
  process.env.REACT_APP_SUPABASE_API_KEY,
);

export { supabase };
