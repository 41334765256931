import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { Box, Center, Heading, Link, useInterval } from '@chakra-ui/react';
import CleaningPage from './pages/CleaningPage';
import InitPage from './pages/InitPage';
import ServicePage from './pages/ServicePage';
import { dispatchFormantDevice, dispatchRestaurantLocation } from './store';
import { authenticateAndGetDevice } from './formant';

import { useStore } from './store/useStore';
import Tabs from './components/Tabs';
import { FormantNotification } from './interfaces/formantNotification';
import { useGeneralStore } from './stores/useGeneralStore';
import { addNotifications, getNotifications } from './utils/notification';
import { NotificationDisplay } from './components/Notifications/NotificationsModal';

const POLLING_FREQUENCY = 3000;

function App() {
  const { dispatch, store } = useStore();
  const { formantDevice, restaurantLocation } = store;
  const setNotifications = useGeneralStore.use.setNotifications();
  const setLastNotification = useGeneralStore.use.setLastNotification();

  const findNewFormantNotifications = async (
    beginning?: string,
  ): Promise<FormantNotification[]> => {
    if (formantDevice) {
      const newNotifications = await formantDevice.getTelemetry(
        'General.notification',
        beginning
          ? dayjs(beginning).add(1, 'second').toDate()
          : dayjs().hour(6).toDate(),
        dayjs().toDate(),
      );
      if (newNotifications && newNotifications.length > 0) {
        const formatedNotifications: FormantNotification[] =
          newNotifications[0].points.map((notification) => {
            return JSON.parse(notification[1]) as FormantNotification;
          });
        return [...formatedNotifications];
      }
    }
    return [];
  };

  useEffect(() => {
    const getFormantDevices = async () => {
      const device = await authenticateAndGetDevice();
      dispatch(dispatchFormantDevice(device));
      const formantRestaurantLocation = device?.tags?.restaurant;
      if (formantRestaurantLocation) {
        dispatch(dispatchRestaurantLocation(formantRestaurantLocation));
      }
    };
    getFormantDevices();
  }, []);

  const getTelemetry = async () => {
    if (formantDevice && restaurantLocation) {
      const returnedNotifications = await getNotifications(restaurantLocation);
      const { lastNotification, notifications: databaseNotification } =
        returnedNotifications || {};
      setLastNotification(lastNotification || null);
      const newNotifications = await findNewFormantNotifications(
        lastNotification?.timestamp,
      );
      if (newNotifications && newNotifications.length > 0) {
        await addNotifications(
          [...newNotifications],
          databaseNotification ? [...databaseNotification] : [],
          setNotifications,
          setLastNotification,
          restaurantLocation,
        );
      } else {
        const updatedNotifications = databaseNotification?.filter(
          (notification) => {
            return (
              notification.name !== 'Maxon error detected' &&
              notification.name !== 'Maxon critical error detected'
            );
          },
        );
        setNotifications(updatedNotifications ? [...updatedNotifications] : []);
      }
    }
  };

  useEffect(() => {
    getTelemetry();
  }, [restaurantLocation]);

  useInterval(() => {
    getTelemetry();
  }, POLLING_FREQUENCY);

  if (!store.formantDevice) {
    return (
      <Center h="100vh" bg="gray.100" flexDirection="column">
        <Heading>
          Connecte toi via Formant pour pouvoir accéder au robot
        </Heading>
        <Link marginTop={10} href="https://app.formant.io/login" isExternal>
          <Heading color="blue.600">Se connecter</Heading>
        </Link>
      </Center>
    );
  }

  return (
    <Box>
      <NotificationDisplay />
      <Routes>
        <Route path="/" element={<Tabs />}>
          <Route path="/" element={<InitPage />} />
          <Route path="/start" element={<InitPage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/cleaning" element={<CleaningPage />} />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
