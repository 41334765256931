import { ICleaningStatus, IPastaSystem } from '../../interfaces/pasta';
import { SystemStatus } from '../../interfaces/system';

const defaultCleaningStatus: ICleaningStatus = {
  step: null,
  start: null,
  predictedEnd: null,
};

const defaultPastaSystem: IPastaSystem = {
  leftPastaContainer: null,
  rightPastaContainer: null,
  currentDishesCooking: 0,
  status: SystemStatus.IDLE,
  cleaningStatus: defaultCleaningStatus,
  isAutoCookingActivated: false,
};
export interface IPastaStore {
  pastaSystem: IPastaSystem | null;
  // formantPastaChannel: null;
}

export const initialPastaStore: IPastaStore = {
  pastaSystem: defaultPastaSystem,
  // formantPastaChannel: null,
};
