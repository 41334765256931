/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Card,
  Grid,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { UpdateAutoCookerPastaFrequencyParameters } from '../../../interfaces/pasta';
import { useStore } from '../../../store';
import {
  updatePastaAutoCookerFrequency,
  updatePastaAutoCookerStatus,
} from '../../../utils/pasta';

export function AutoCooking() {
  const {
    store: { formantDevice, pastaSystem },
  } = useStore();
  const { isAutoCookingActivated } = pastaSystem || {
    isAutoCookingActivated: false,
  };
  const [autoCookingSpeed, setAutoCookingSpeed] = useState(60);
  const [autoCookingState, setAutoCookingState] = useState<boolean>(
    isAutoCookingActivated,
  );
  const [isCommandLoading, setIsCommandLoading] = useState(false);

  const updateAutoCookingSpeed = async () => {
    if (!formantDevice) return;
    const frequency: UpdateAutoCookerPastaFrequencyParameters = {
      dish_frequency_per_hour: autoCookingSpeed,
    };
    await updatePastaAutoCookerFrequency(formantDevice, frequency);
  };

  useEffect(() => {
    if (isAutoCookingActivated !== autoCookingState) {
      setAutoCookingState(isAutoCookingActivated);
      setIsCommandLoading(false);
    }
  }, [isAutoCookingActivated]);

  const updateAutoCookingState = async (state: number) => {
    if (!formantDevice) return;
    await updatePastaAutoCookerStatus(formantDevice, {
      auto_cooker_status: state,
    });
  };

  const toggleAutoCooking = async () => {
    if (!formantDevice) return;

    if (!autoCookingState) {
      await updateAutoCookingSpeed();
      await updateAutoCookingState(1);
      setIsCommandLoading(true);
    } else {
      await updateAutoCookingState(0);
      setIsCommandLoading(true);
    }
  };

  return (
    <Card
      p="3"
      border={2}
      borderStyle="solid"
      borderColor={!autoCookingState ? 'red' : 'green'}>
      <Stack>
        <Grid templateColumns="1fr 1fr" gap={4}>
          <Heading as="h4" size="md" className="component-heading-small" pt="0">
            Auto-cuisson
          </Heading>
          <Button
            onClick={() => toggleAutoCooking()}
            backgroundColor={autoCookingState ? 'red' : 'green'}
            color="white"
            isLoading={isCommandLoading}>
            {autoCookingState ? 'Désactiver' : 'Activer'}
          </Button>
          {/* <Switch
            size="lg"
            colorScheme="green"
            pt="3"
            value={autoCookingState}
            onChange={() => toggleAutoCookingSwitch()}
          /> */}
        </Grid>
        {/* <Text fontSize="sm" color="gray.500">
          Coming soon
        </Text> */}

        <Grid templateColumns="1fr 1fr" gap={4} pt="2">
          <Text display="flex" alignItems="center">
            Nombre de plat par heure
          </Text>

          <NumberInput
            defaultValue={autoCookingSpeed}
            max={60}
            size="lg"
            value={autoCookingSpeed}
            // isDisabled={!autoCookingState}
            onChange={(number) => setAutoCookingSpeed(Number(number))}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Grid>
      </Stack>
    </Card>
  );
}
