import { Device } from '@formant/data-sdk';
import { FormantNotification } from '../../interfaces/formantNotification';
import { IDispatch } from '../reducers';

export const dispatchFormantDevice = (device: Device): IDispatch => ({
  type: 'setFormantDevice',
  payload: device,
});

export const dispatchRestaurantLocation = (location: string): IDispatch => ({
  type: 'setRestaurantLocation',
  payload: location,
});

export const dispatchGeneralNotifications = (
  notifications: FormantNotification[],
): IDispatch => ({
  type: 'setNotifications',
  payload: notifications,
});
