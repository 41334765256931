import { Card, Box, Text, Flex, Badge, Switch } from '@chakra-ui/react';
import { GiKitchenScale } from 'react-icons/gi';
import { ISauceContainer } from '../../../interfaces/sauce';

type SauceContainerProps = {
  sauceContainer: ISauceContainer;
  sauceContainersWithNewState?: ISauceContainer[];
  setSauceContainersWithNewState?: (sauceContainers: ISauceContainer[]) => void;
};

function SauceContainer({
  sauceContainer,
  sauceContainersWithNewState,
  setSauceContainersWithNewState,
}: SauceContainerProps) {
  const { name, mass, enabled, isHeating } = sauceContainer;

  const changeHeatingState = () => {
    if (setSauceContainersWithNewState && sauceContainersWithNewState) {
      const newSauceContainers = sauceContainersWithNewState.map(
        (container) => {
          if (container.id === sauceContainer.id) {
            return {
              ...container,
              isHeating: !isHeating,
            };
          }
          return container;
        },
      );
      setSauceContainersWithNewState(newSauceContainers);
    }
  };

  const changeContainerState = (state: boolean) => {
    if (setSauceContainersWithNewState && sauceContainersWithNewState) {
      const newSauceContainers: ISauceContainer[] =
        sauceContainersWithNewState.map((container: ISauceContainer) => {
          if (container.id === sauceContainer.id) {
            return {
              ...container,
              enabled: state,
            };
          }
          return container;
        });
      setSauceContainersWithNewState([...newSauceContainers]);
    }
  };

  const getGetMass = () => {
    return mass ? `${(mass / 1000).toFixed(2)} kg ` : '0 kg ';
  };

  return (
    <Card variant="outline" align="center">
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        height="100%"
        minHeight={300}
        p={4}>
        <Box>
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {`${enabled ? '🔥 ✅ ' : '❄️ ❌ '} ${name}`}
          </Text>
          <Box>
            <Flex align="center" justifyContent="center" />
            <Box mt={2} textAlign="center">
              {enabled && (
                <Flex align="center" justifyContent="center">
                  <Switch
                    size="sm"
                    colorScheme="green"
                    isChecked={isHeating}
                    onChange={() => changeHeatingState()}
                  />
                  <Text ml={2} fontSize="sm" marginTop={2}>
                    {isHeating ? 'Chauffage activé' : 'Chauffage désactivé'}
                  </Text>
                </Flex>
              )}
            </Box>
          </Box>
        </Box>

        <Box>
          <Text fontWeight="bold" textAlign="center">
            Remplissage
          </Text>
          <Flex align="center" justifyContent="center">
            <Badge
              variant="subtle"
              colorScheme="gray"
              textAlign="center"
              fontSize="xl">
              <Flex align="center" justify="center" gap={1}>
                <span>{getGetMass()}</span>
                <GiKitchenScale size={20} />
              </Flex>
            </Badge>
          </Flex>
          {sauceContainersWithNewState && (
            <Box mt={2} textAlign="center" minHeight={10}>
              <Flex align="center" justifyContent="center">
                <Switch
                  mt={2}
                  size="sm"
                  colorScheme="green"
                  isChecked={enabled}
                  onChange={(e) => changeContainerState(e.target.checked)}
                />
                <Text ml={2} fontSize="sm" marginTop={2}>
                  {enabled ? 'Cuve activée' : 'Cuve désactivée'}
                </Text>
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>
    </Card>
  );
}

export default SauceContainer;
