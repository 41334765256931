import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, ButtonGroup, Flex, IconButton, Text } from '@chakra-ui/react';

type NotificationNavigationProps = {
  index: number;
  setIndex: (index: number) => void;
  notificationNumber: number;
};

export function NotificationNavigation({
  index,
  setIndex,
  notificationNumber,
}: NotificationNavigationProps) {
  const handlePreviousNotification = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleNextNotification = () => {
    if (index < notificationNumber - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <Flex alignItems="center" justifyContent="center" marginTop="10">
      <ButtonGroup spacing="6" alignItems="center" justifyContent="center">
        {index > 0 ? (
          <IconButton
            colorScheme="gray"
            aria-label="Previous notification button"
            onClick={() => handlePreviousNotification()}
            size="lg"
            icon={<ArrowBackIcon boxSize={7} />}
          />
        ) : (
          <Box boxSize={12} />
        )}
        <Text>
          {index + 1} / {notificationNumber}
        </Text>
        {index < notificationNumber - 1 ? (
          <IconButton
            colorScheme="gray"
            aria-label="Next notification button"
            onClick={() => handleNextNotification()}
            size="lg"
            icon={<ArrowForwardIcon boxSize={7} />}
          />
        ) : (
          <Box boxSize={12} />
        )}
      </ButtonGroup>
    </Flex>
  );
}
